import { action, Action, Thunk, thunk } from 'easy-peasy';
import axios from '../axiosConfig';
import { OpportunityStatusEnum, SaveOpportunityPayloadType } from '../types/opportunity';

interface OpportunityDetailModelProps {
    requestSearchState: null | 'loading' | 'error' | 'success';
    requestItemsSearchState: null | 'loading' | 'error' | 'success';
    requestSaveOpportunityState: {state: null | 'loading' | 'error' | 'success', message?: null | string};
    opportunityDetail: Record<string, any> | null;
    opportunityHeader: Record<string, any> | null;
    suppliersList: Array<{suppcatnum: string, nombre_proveedor: string}>
    firmsList: Array<{firmname: string}>
    filteredItems: Array<Record<string, any>> | null
    sellingPrice: Record<string, {index: string, value: number | null}>
 
    requestOpportunityDetail: Thunk<OpportunityDetailModelProps, {idOpportunity: string, nombreCliente: string}>;
    requestFilterOptions: Thunk<OpportunityDetailModelProps>;
    requestFilteredItems: Thunk<OpportunityDetailModelProps, {
        page: number | null,
        rowsPerPage: number | null,
        item_code ? : string | null,
        modelo ? : string | null,
        item_name ? : string | null,
        nombre_proveedor ? : string | null,
        firm_name ? : string | null,
    }>;
    requestSaveOpportunity: Thunk<OpportunityDetailModelProps, SaveOpportunityPayloadType>;
    requestCreateSAP: Thunk<OpportunityDetailModelProps, SaveOpportunityPayloadType>;
    requestDownloadOfferPDF: Thunk<OpportunityDetailModelProps, string>;
    
    setOpportunityDetail: Action<OpportunityDetailModelProps, Record<string, any> | null>;
    setOpportunityHeader: Action<OpportunityDetailModelProps, Record<string, any> | null>;
    setRequestSearchState: Action<OpportunityDetailModelProps, null | 'loading' | 'error' | 'success'>;
    setRequestItemsSearchState: Action<OpportunityDetailModelProps, null | 'loading' | 'error' | 'success'>;
    setRequestSaveOpportunityState: Action<OpportunityDetailModelProps, {state: null | 'loading' | 'error' | 'success', message?: string | null}>;
    setSuppliersList: Action<OpportunityDetailModelProps, Array<{suppcatnum: string, nombre_proveedor: string}>>;
    setFirmsList: Action<OpportunityDetailModelProps, Array<{firmname: string}>>;
    setFilteredItems: Action<OpportunityDetailModelProps, Array<Record<string, any>> | null >;
    setNewMatch: Action<OpportunityDetailModelProps, Record<string, any> >;
    setItemsToOffer: Action<OpportunityDetailModelProps, {item: Record<string, any>, isChecked: boolean} >;
    setSellingPrice: Action<OpportunityDetailModelProps, Record<string, any>>;

}

const opportunityDetailModel: OpportunityDetailModelProps = {

    opportunityDetail: null,
    opportunityHeader: null,
    requestSearchState: null,
    requestItemsSearchState: null,
    requestSaveOpportunityState: {state: null, message: null},
    suppliersList: [],
    firmsList: [],
    filteredItems: null,
    sellingPrice: {},

    setOpportunityDetail: action((state, payload) => {
        state.opportunityDetail = payload;
    }),
    setOpportunityHeader: action((state, payload) => {
        state.opportunityHeader = payload;
    }),
    setRequestSearchState: action((state, payload) => {
        state.requestSearchState = payload;
    }),
    setRequestItemsSearchState: action((state, payload) => {
        state.requestItemsSearchState = payload;
    }),
    setRequestSaveOpportunityState: action((state, payload: {state: null | 'loading' | 'error' | 'success', message?: string | null}) => {
        state.requestSaveOpportunityState = payload;
    }),
    setSuppliersList: action((state, payload) => {
        state.suppliersList = payload;
    }),
    setFirmsList: action((state, payload) => {
        state.firmsList = payload;
    }),
    setFilteredItems: action((state, payload) => {
        state.filteredItems = payload;
    }),
    setItemsToOffer: action((state, payload) => {
        const {
            isChecked,
            item
        } = payload
        
        if(!state.opportunityDetail) return
        
        state.opportunityDetail[ item["index"] ] = {...state.opportunityDetail[ item["index"] ], "offered": isChecked}
    }),
    setNewMatch: action((state, payload) => {
        const {
            index,
            newItemCode_match_sugerido,
            newItemName_match_sugerido,
            newNombreProveedor_match,
            newCluster_match,
            newSimilitudCoseno_match,
            newScorePriorizacion_match,
            newEstimatedCost,
            newSellingPrice,
            newCategoryRotation,
            newStock,
        } = payload

        if(state.opportunityDetail){
            state.opportunityDetail[index] = {
                ...state.opportunityDetail[index],
                "itemcode_match_sugerido": newItemCode_match_sugerido,
                "itemname_match_sugerido": newItemName_match_sugerido,
                "nombre_proveedor_match": newNombreProveedor_match,
                "cluster_match_sugerido": newCluster_match,
                "similitud_coseno_match_sugerido": newSimilitudCoseno_match,
                "score_priorizacion_match_sugerido": newScorePriorizacion_match,
                "estimated_cost_match_sugerido": newEstimatedCost,
                "selling_price_match_sugerido": newSellingPrice,
                "categoria_rotacion": newCategoryRotation,
                "available_clds": newStock,
            }

            state.sellingPrice = {...state.sellingPrice, [index]: {index, value: newSellingPrice} }
        }
    }),
    setSellingPrice: action((state, {detail_item, value}) => {
        const {index} = detail_item
        state.sellingPrice = {...state.sellingPrice, [index]: {index, value: parseFloat(value)} }
    }),


    requestFilterOptions: thunk(async (actions, payload, { getState }) => {
        try {
            const sup_response = await axios.get('/filters/suppliers_list');
            actions.setSuppliersList(sup_response.data['data'] || [])
        } catch (error) {
            console.error('Error fetching suppliers_list:', error);
            return false;
        }

        try {
            const firms_response = await axios.get('/filters/firms_list');
            actions.setFirmsList(firms_response.data['data'] || [])
        } catch (error) {
            console.error('Error fetching firms_list:', error);
            return false;
        }
    }),

    requestFilteredItems: thunk(async (actions, payload, { getState }) => {
        actions.setFilteredItems(null)

        const data = Object.fromEntries(Object.entries(payload).filter(([_, v]) => v != null));

        try {
            const res = await axios.get(
                '/filters/filter_items',
                {
                    params: {...data}
                }
            );
            actions.setFilteredItems(res.data['data'] || [])
            actions.setRequestItemsSearchState(res.status === 200 ? 'success' : 'error')
        } catch (error) {
            actions.setRequestItemsSearchState('error')
            console.error('Error fetching filter_items:', error);
            return false;
        }
    }),

    requestOpportunityDetail: thunk(async (actions, {idOpportunity, nombreCliente}, { getState }) => {
        actions.setRequestSearchState('loading');

        try {
            const response = await axios.get(
                '/opportunity/opportunity-detail',
                {
                    params: {
                        id_opportunity: encodeURIComponent(idOpportunity),
                        nombre_cliente: encodeURIComponent(nombreCliente)
                    }
                }
                
            );

            response.data['data']['detail'].forEach((item: any) => {
                actions.setSellingPrice({ detail_item: item, value: item['selling_price_match_sugerido'] ? parseFloat(item['selling_price_match_sugerido']) : null })
            });
       
            actions.setOpportunityDetail(response.data['data']['detail'])
            actions.setOpportunityHeader(response.data['data']['header'])
            actions.setRequestSearchState(response.status === 200 ? 'success' : 'error')
            
            return true;
        } catch (error) {
            actions.setRequestSearchState('error')
            console.error('Error fetching users:', error);

            return false;
        }
    }),

    requestSaveOpportunity: thunk(async (actions, payload, { getState }) => {
        
        const {sellingPrice, opportunityHeader} = getState()

        let response : Record<string, any> = {}

        payload['items'] = payload['items'].map((item: any) => {
            let newItem = {...item, selling_price: sellingPrice[ item['index'] ]['value'] }
            delete newItem['index']
            return newItem
        })

        if(payload.id_opportunities_table){
            /* --------------------------- EDITAR OPORTUNIDAD --------------------------- */
            try {
                response = await axios.post(
                    `/opportunity/update/${payload.id_opportunities_table}`,
                    {
                        ...payload
                    },
                    {
                        headers: { 'Content-Type': 'application/json' }
                    }
                );
                
            } catch (error) {
                actions.setRequestSaveOpportunityState({state: 'error'})
                console.error('Error updating opportunity:', error);
    
                return false;
            }
        }
        else{
            /* ---------------------------- CREAR OPORTUNIDAD --------------------------- */
            try {
                response = await axios.post(
                    '/opportunity/create',
                    {
                        ...payload
                    },
                    {
                        headers: { 'Content-Type': 'application/json' }
                    }
                );
            } catch (error) {
                actions.setRequestSaveOpportunityState({state: 'error'})
                console.error('Error creating opportunity:', error);
            }
        }

        if(response.status === 200 && !response.data['error']){
            actions.setRequestSaveOpportunityState({state: 'success'})
            actions.setOpportunityHeader(
                {
                    ...opportunityHeader,
                    id_opportunities_table: response.data['data']['id'],
                    status: response.data['data']['status']
                }
            )
        } else {
            actions.setRequestSaveOpportunityState({state: 'error'})
        }

    }),

    requestCreateSAP: thunk(async (actions, payload, { getState }) => {
        
        const {sellingPrice, opportunityHeader} = getState()

        let response : Record<string, any> = {}

        payload['items'] = payload['items'].map((item: any) => {
            let newItem = {...item, selling_price: sellingPrice[ item['index'] ]['value'] }
            delete newItem['index']
            return newItem
        })

        if(payload.id_opportunities_table && payload.status === OpportunityStatusEnum.OFFERTED){
            /* ----------------------- GUARDAR OPORTUNIDAD EN SAP ----------------------- */
            try {
                response = await axios.post(
                    `/opportunity/create_sap/`,
                    {
                        ...payload
                    },
                    {
                        headers: { 'Content-Type': 'application/json' }
                    }
                );
                
            } catch (error) {
                actions.setRequestSaveOpportunityState({state: 'error'})
                console.error('Error creating opportunity in SAP:', error);
                return false;
            }
        }

        if(response.status === 200 && !response.data['error']){
            actions.setRequestSaveOpportunityState({state: 'success'})
            actions.setOpportunityHeader(
                {
                    ...opportunityHeader,
                    id_opportunities_table: response.data['data']['id'],
                    status: response.data['data']['status'],
                    number_opportunity_sap: response.data['data']['number_opportunity_sap'],
                    doc_entry: response.data['data']['doc_entry'],
                }
            )
        } else {
            actions.setRequestSaveOpportunityState({state: 'error', message: response.data['message']})
        }

    }),

    requestDownloadOfferPDF: thunk(async (actions, payload, { getState }) => {
        
        try {
            const response = await axios.get(
                '/opportunity/pdf',
                {
                    params: {
                        doc_entry: encodeURIComponent(payload)
                    },
                    responseType: 'blob'
                }
            );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `oferta_${payload}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error downloading offer pdf:', error);
            return false;
        }

    })
};

export default opportunityDetailModel;