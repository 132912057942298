import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation } from "react-router-dom";
import { useStoreState, useStoreActions } from '../../store';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import logo from '../../assets/out-of-stock.png';
import Typography from '@mui/material/Typography';
import { CircularLoader } from '../../components/circular-loader';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import ImageSlider from '../../components/image-slider/image-slider';
import { Alert, Button, Link, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import parseCurrency from '../../utils/parseCurrency';
import StockTable from '../../components/stock-table';
import { ModalProductEquivalences } from '../../components/modal-product-equivalences';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}


const ProductDetail: FC = () => {
  const navigate = useNavigate();
  const productDetail = useStoreState((state: any) => state.productDetail.productDetail);
  const productStock = useStoreState((state: any) => state.productDetail.productStock);
  const productDetailImages = useStoreState((state: any) => state.productDetail.productDetailImages);

  const requestProductDetail = useStoreActions((actions: any) => actions.productDetail.requestProductDetail);
  const requestProductDetailImages = useStoreActions((actions: any) => actions.productDetail.requestProductDetailImages);

  const requestSearchState = useStoreState((state: any) => state.productDetail.requestSearchState);
  const requestSearchStateImages = useStoreState((state: any) => state.productDetail.requestSearchStateImages);

  const [quantity, setQuantity] = useState<number>(0)
  const [selectedTab, setselectedTab] = useState<number>(0)

  const handleAddToCar = () => {
    hablenOpenSnackbar();
  }

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const hablenOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const selectTab = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  const action = (
    <>
      <Button color="primary" size="small" onClick={handleCloseSnackbar}>
        Deshacer
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  const keysShowProductTable = [
    { key: 'nombre_proveedor', label: 'Proveedor' },
    { key: 'itemcode', label: 'Código Proveedor (SKU)' },
    { key: 'suppcatnum', label: 'Part No.' },
    { key: 'firmname', label: 'Fabricante' },
    { key: 'procedencia', label: 'procedencia' },
    { key: 'categoria_rotacion', label: 'Rotación' },
    { key: 'clasificacion_producto', label: 'Clasificación' },
    { key: 'cantidad_acuerdos_con_clientes', label: 'Cantidad Acuerdos Comerciales' },
  ]

  const alertsShowProduct = [
    { key: 'activo_para_venta', yes_label: 'Activo Venta', no_label: 'No Activo para Venta' },
    { key: 'activo_para_compra', yes_label: 'Activo Compra', no_label: 'No Activo para Compra' },
    { key: 'item_descontinuado', yes_label: 'Vigente', no_label: 'Descontinuado' },
  ]

  let query = useQuery();

  const itemcode = query.get('itemcode')

  useEffect(() => {
    requestProductDetail(itemcode)
    requestProductDetailImages(itemcode)
  }, []);

  if (requestSearchState === 'loading') return (
    <CircularLoader state={requestSearchState} />
  )

  if (!productDetail && requestSearchState !== 'loading') return (
    <Box
      sx={{
        display: 'flex',
        height: '100%'
      }}
    >
      <Card
        sx={{
          backgroundColor: 'white',
          width: '50%',
          margin: ' auto',
          height: '50%',
          borderRadius: '30px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <Box
          component="img"
          src={logo}
          alt="buscar"
          sx={
            {
              width: '50%',
            }
          }
        />
        <Typography variant='subtitle1' >
          Producto con itemcode: {query.get('itemcode')} No encontrado
        </Typography>
      </Card>
    </Box>
  )

  return (
    <Paper
      elevation={3}
      sx={{
        height: '100%',
        width: '90%',
        backgroundColor: 'white',
        margin: '2em auto',
        padding: '1em',
        position: 'relative'
      }}
    >
      <Box>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={2000}
          onClose={handleCloseSnackbar}
          message={`Producto Agregado: X${quantity} ${productDetail['itemname']}`}
          action={action}
        />
        <Button
                variant="contained"
                onClick={() => navigate(-1)}
                sx={{
                  backgroundColor: '#343842',
                  padding: '3px',
                  minWidth: '50px',
                  marginBottom: '1em',
                  '&:hover': { 
                    'backgroundColor': '#e1e1e1', 'color': 'black'
                  }
                }}
              >
                <ArrowBackIcon />
          </Button>
        <Grid
          container
          sx={{
            height: '75%',
          }}>
          <Grid
            item
            xs={5}
            sx={{
              display: 'flex',
              alignItems: 'start',
            }}
          >
            <Box
              sx={{
                height: '100%',
                width: '90%',
                margin: 'auto',
              }}
            >
              {
                requestSearchStateImages === 'loading' ?
                  <Skeleton width='100%' height='100%' />
                  : (
                    productDetailImages?.length > 0 ?
                      <ImageSlider images={{ 'images': productDetailImages, 'type': 'base64' }} /> :
                      <ImageSlider images={{ 'images': [productDetail['link_imagen']], 'type': 'url' }} />
                  )
              }
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Stack>
              <Stack>
                <Typography
                  variant='h4'
                  sx={{
                    textTransform: 'capitalize'
                  }}
                >
                  {productDetail['itemname'].toLowerCase()}
                </Typography>
              </Stack>
              <Stack>
                <Typography variant='subtitle1'>
                  SKU: {productDetail['itemcode']}
                </Typography>
              </Stack>
              <ModalProductEquivalences product={productDetail} />
            </Stack>

            <Stack>
              <Typography
                variant='h3'
                sx={{
                  fontWeight: 'lighter',
                  marginTop: '15px'
                }}
              >
                {
                  parseCurrency(
                    productDetail['precio_adquisicion_sin_iva'] ? productDetail['precio_adquisicion_sin_iva'] : productDetail['precio_web_sin_iva'] || '')
                }
              </Typography>
              <Typography
                variant='caption'
              >
                {productDetail['salunitmsr']}
              </Typography>
            </Stack>

            <Stack
              direction="row"
              spacing={2}
              sx={{
                margin: '10px'
              }}
            >
              {
                alertsShowProduct.map(({ key, yes_label, no_label }, idx) =>
                  <Alert
                    key={idx}
                    severity={['si', 'no desc.'].includes(productDetail[key].toLowerCase()) ? "success" : "error"}
                  >
                    {['si', 'no desc.'].includes(productDetail[key].toLowerCase()) ? yes_label : no_label}
                  </Alert>
                )
              }
            </Stack>

            {/*<Stack
              direction='row'
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={3}
              sx={{
                margin: '20px 0'
              }}
            >
              <TextField
                id="quantity"
                label="Cantidad"
                type="number"
                value={quantity}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                helperText={'Total Disponible: ' + (productDetail['available_clds'] ? productDetail['available_clds'] : '0')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setQuantity((current) => (
                    parseInt(event.target.value) > 0 && parseInt(event.target.value) <= productDetail['available_clds']
                  ) ? parseInt(event.target.value) : current);
                }}
              />
              <Button
                disabled={quantity > productDetail['available_clds']}
                variant="contained"
                startIcon={<AddShoppingCartIcon />}
                onClick={handleAddToCar}
                sx={{
                  height: 'fit-content'
                }}
              >
                Agregar Al Carrito
              </Button>
            </Stack>*/}

            <Stack>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    {
                      keysShowProductTable.map(({ key, label }: Record<string, string>, idx: number) =>
                        <TableRow key={idx}>
                          <TableCell component="th" scope="row">
                            {label}
                          </TableCell>
                          <TableCell>
                            {productDetail[key] || '-'}
                          </TableCell>
                        </TableRow>
                      )
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Grid>
        </Grid>
        <Tabs value={selectedTab} onChange={(event, val) => setselectedTab(val)} aria-label="basic tabs example">
          <Tab label="Descripción" {...selectTab(0)} />
          <Tab label="Stock" {...selectTab(1)} />
          <Tab label="Documentos" {...selectTab(2)} />
        </Tabs>
        <CustomTabPanel value={selectedTab} index={0}>
          <Box
            sx={{
              margin: '10px 0'
            }}
          >

            <Stack
              sx={{
                border: '1px solid #e0e0e0'
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: productDetail['usertext'] }}></div>
            </Stack>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={selectedTab} index={1}>
          <TableContainer component={Paper}>
            <StockTable items={productStock} />
          </TableContainer>
        </CustomTabPanel>
        <CustomTabPanel value={selectedTab} index={2}>
          <TableContainer component={Paper} sx={{width: '50%', margin: 'auto'}} >
            <Table aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell align="left">Ficha técnica</TableCell>
                  <TableCell align="left">
                    {productDetail['link_ficha_tecnica'] != null ? 
                      <Link
                        href={productDetail['link_ficha_tecnica']}
                        color="primary"
                        underline="always"
                      >
                          Descargar
                      </Link>
                      : "N/A"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Hoja de seguridad</TableCell>
                  <TableCell align="left">{productDetail['link_hoja_seguridad'] != null ? productDetail['link_hoja_seguridad'] : "N/A"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CustomTabPanel>
      </Box>
    </Paper >
  )
}

export default ProductDetail;