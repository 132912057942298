import type { ChangeEvent, FC, MouseEvent } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import type { Product } from '../types/product';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import Tooltip from '@mui/material/Tooltip';
import ProductImage from './product-image';
import parseCurrency from '../utils/parseCurrency';
import serviall_logo from '../assets/serviall_logo.png';
import { Alert, Typography } from '@mui/material';
import { RotationChip } from '../utils/rotation_category';
import ParametersChip from './parameters-chip';
import StockAlerts from './stock-alerts';
import { useNavigate } from 'react-router-dom';
import { ModalProductEquivalences } from './modal-product-equivalences';

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
    order: Order;
    orderBy: string;
    headCells: Array<{ id: string, label: string, tooltip: string }>;
}
type Order = 'asc' | 'desc';

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort, headCells } =
        props;
    const createSortHandler =
        (property: any) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead
            sx={{
                position: 'sticky',
                top: '80px',
                '.MuiTableCell-root': {
                    backgroundColor: '#ef3234',
                    color: 'white',
                    '.Mui-active': {
                        backgroundColor: '#ef3234',
                        color: 'white',
                        fontWeight: 'bold',
                        '.MuiTableSortLabel-icon': {
                            color: 'white',
                        },
                    },
                },
                '.MuiTableSortLabel-root': {
                    '&:hover': {
                        color: 'white',
                        '.MuiTableSortLabel-icon': {
                            opacity: '1',
                        },
                    }
                }
            }}
        >
            <TableRow>
                <TableCell />
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{
                            '&:hover': {
                                cursor: 'pointer',
                            }
                        }}
                        onClick={createSortHandler(headCell.id)}
                    >
                        <Tooltip title={headCell.tooltip}>
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}>
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </Tooltip>
                    </TableCell>
                ))}
                <TableCell>Parámetros</TableCell>
            </TableRow>
        </TableHead>
    );
}

interface ProductListTableProps {
    count?: number;
    items?: Product[];
    activeFilters?: any;
    onPageChange?: (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
    onRowsPerPageChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onChangeFilters: (data: any, checked: boolean) => void;
    page?: number;
    rowsPerPage?: number;
    headers: Array<{ id: string, label: string, tooltip: string }>;
}

export const ProductListTable: FC<ProductListTableProps> = (props) => {
    const {
        count = 10,
        items = [],
        activeFilters,
        onPageChange = () => { },
        onRowsPerPageChange,
        onChangeFilters,
        page = 0,
        rowsPerPage = 10,
        headers = []
    } = props;

    const navigate = useNavigate();

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: any,
    ) => {
        const isAsc = activeFilters.order.orderBy === property && activeFilters.order.order === 'asc';
        onChangeFilters({ endpoint: 'order', item: { order: isAsc ? 'desc' : 'asc', orderBy: property } }, true)
    };

    const handleRowClick = (event: React.MouseEvent<HTMLTableRowElement>, row: Product) => {
        navigate(`/detalle-producto?itemcode=${row.itemcode}`)
    };

    return (
        <Box
            sx={{
                border: '1px solid #f0f0f0',
                width: '100%',
            }}
        >
            <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size='medium'
            >
                <EnhancedTableHead
                    order={activeFilters.order.order}
                    orderBy={activeFilters.order.orderBy}
                    onRequestSort={handleRequestSort}
                    headCells={headers}
                />

                <TableBody>
                    {items.map((row, index) => {
                        return (
                            <TableRow
                                hover
                                onClick={(event) => handleRowClick(event, row)}
                                tabIndex={-1}
                                key={index}
                                sx={{
                                    cursor: 'pointer',
                                    textDecoration: 'none',
                                    ...(
                                        row.item_descontinuado !== 'No Desc.' && {
                                            fontStyle: 'italic',
                                            '.MuiTableCell-root': {
                                                color: 'gray',
                                            }
                                        }
                                    )
                                }}
                            >
                                <TableCell>
                                    <Box
                                        sx={{
                                            alignItems: 'center',
                                            display: 'flex'
                                        }}
                                    >
                                        <ProductImage itemcode={row.itemcode} width={'120px'} height={'120px'} link_imagen={row.link_imagen} />
                                    </Box>
                                </TableCell>
                                <TableCell align="left">
                                    {
                                        row.itemcode &&
                                        <Typography
                                            variant='body2'
                                            color='#424242'
                                        >
                                            SKU: {row.itemcode}
                                        </Typography>
                                    }
                                    <Typography fontWeight='500' variant='body1'>
                                        {row.itemname}
                                    </Typography>
                                    {
                                        row.item_descontinuado !== 'No Desc.' &&
                                        <Typography
                                            variant='caption'
                                            sx={{
                                                color: 'red',
                                                fontStyle: 'normal'
                                            }}
                                        >
                                            Producto Descontinuado
                                        </Typography>
                                    }
                                    <ModalProductEquivalences product={row} />
                                </TableCell>
                                <TableCell align="left">
                                    {
                                        row.lictradnum === '76035324-8' ?
                                            <Box
                                                component='img'
                                                src={serviall_logo}
                                                width='90px'
                                            />
                                            : row.nombre_proveedor
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    <StockAlerts stock={row.available_clds} />
                                </TableCell>
                                <TableCell align="left">{row.firmname ? row.firmname : '-'}</TableCell>
                                <TableCell align="left">{row.suppcatnum ? row.suppcatnum : '-'}</TableCell>
                                <TableCell align="left">
                                    <RotationChip rotation_category={row.categoria_rotacion} />
                                </TableCell>
                                <TableCell align="left">
                                    {
                                        !row.precio_adquisicion_sin_iva ?
                                            <Alert
                                                severity='info'
                                                sx={{
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-around',
                                                    textAlign: 'center',
                                                    color: 'black',
                                                    '.MuiAlert-icon': {
                                                        margin: 0,
                                                        padding: 0
                                                    }
                                                }}
                                            >
                                                Sin Información
                                            </Alert>
                                            : parseCurrency(row.precio_adquisicion_sin_iva)
                                    }
                                </TableCell>
                                <TableCell>
                                    <ParametersChip product={row} direction='column' />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>

            <TablePagination
                component="div"
                count={count}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
            />
        </Box >
    );
};

ProductListTable.propTypes = {
    count: PropTypes.number,
    items: PropTypes.array,
    onPageChange: PropTypes.func,
    onRowsPerPageChange: PropTypes.func,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number
};
