import { action, Action, Thunk, thunk } from 'easy-peasy';
import axios from '../axiosConfig';

interface UserModel {
    users: [];
    page: number;
    rowsPerPage: number;
    requestSearchState: null | 'loading' | 'error' | 'success';
    setusers: Action<UserModel, []>;
    setPage: Action<UserModel, number>;
    setRowsPerPage: Action<UserModel, number>;
    setRequestSearchState: Action<UserModel, null | 'loading' | 'error' | 'success'>;
    list: Thunk<UserModel>;
    create: Thunk<UserModel>;
    update: Thunk<UserModel>;
    updatePassword: Thunk<UserModel, Record<string, string>>;
}

const userModel: UserModel = {
    users: [],
    page: 1,
    rowsPerPage: 5,
    requestSearchState: null,
    setusers: action((state, payload) => {
        state.users = payload;
    }),
    setRequestSearchState: action((state, payload) => {
        state.requestSearchState = payload;
    }),
    setPage: action((state, payload) => {
        state.page = payload;
    }),
    setRowsPerPage: action((state, payload) => {
        state.rowsPerPage = payload;
    }),
    create: thunk(async (actions, values: any, {getState}) => {
        try {
            await axios.post(`/user/create`, { ...values }, {
                headers: { 'Content-Type': 'application/json' } // Establece el tipo de contenido
            });
            actions.list()
            return true;
        } catch (error) {
            console.error('Error fetching users:', error);
            return false;
        }
    }),
    list: thunk(async (actions, values, {getState}) => {
        actions.setRequestSearchState('loading')
        const {page, rowsPerPage} = getState()
        try {
            const response = await axios.get('/user/list', {
                params: {page, size: rowsPerPage},
                headers: { 'Content-Type': 'application/json' } // Establece el tipo de contenido
            });
            actions.setusers(response.data);
            actions.setRequestSearchState(response.status === 200 ? 'success' : 'error')
            return true;
        } catch (error) {
            console.error('Error fetching users:', error);
            actions.setRequestSearchState('error')
            return false;
        }

    }),
    update: thunk(async (actions, values: any) => {
        try {
            await axios.put(`/user/update/${values.id}`, { ...values }, {
                headers: { 'Content-Type': 'application/json' } // Establece el tipo de contenido
            });
            actions.list()
            return true;
        } catch (error) {
            console.error('Error fetching users:', error);
            return false;
        }
    }),
    updatePassword: thunk(async (actions, values: any) => {
        try {
            await axios.put(`/user/update-password/${values.id}`, { ...values }, {
                headers: { 'Content-Type': 'application/json' } // Establece el tipo de contenido
            });
            return true;
        } catch (error) {
            console.error('Error fetching users:', error);
            return false;
        }

    }),
};

export default userModel;
