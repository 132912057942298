import { Chip, Stack } from '@mui/material';
import React from 'react';
import CheckIcon from '@mui/icons-material/CheckBoxRounded';
import CloseIcon from '@mui/icons-material/CancelRounded';
import { Product } from '../types/product';

interface ParametersChipProps {
    product: Product;
    direction: 'row' | 'row-reverse' | 'column' | 'column-reverse';
}

const ParametersChip: React.FC<ParametersChipProps> = ({ product, direction }) => {
    const { activo_en_sap, activo_para_venta, activo_para_compra } = product;

    const data = [
        {
            label: 'SAP',
            value: activo_en_sap
        },
        {
            label: 'Venta',
            value: activo_para_venta
        },
        {
            label: 'Compra',
            value: activo_para_compra
        },
    ]

    return (
        <Stack direction={direction} spacing={0.5}>
            {data.map((item, index) =>
                <Chip
                    key={index}
                    sx={{
                        textAlign: 'left',
                        justifyContent: 'left',
                        '.MuiChip-icon': {
                            color: 'white'
                        }
                    }}
                    size='small'
                    {
                    ...(
                        item.value === 'Si' || item.value === 'Vigente' ?
                            {
                                label: item.label,
                                icon: <CheckIcon />,
                                color: 'success',
                            } :
                            {
                                label: item.label,
                                icon: <CloseIcon />,
                                color: 'warning',
                            }

                    )
                    }
                />
            )
            }
        </Stack>
    );
};

export default ParametersChip;