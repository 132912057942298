import type { ChangeEvent, FC, MouseEvent } from 'react';
import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import type { Product } from '../types/product';
import EnhancedTableHead, { getComparator, stableSort } from './enhanced-table-head';
import { Checkbox } from '@mui/material';
import { useStoreActions } from '../store';
import parseCurrency from '../utils/parseCurrency';

type Order = 'asc' | 'desc';

interface ProductListTableItemsProps {
    count?: number;
    items?: Product[];
    onPageChange?: (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
    onRowsPerPageChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    page?: number;
    rowsPerPage?: number;
    headers: Array<{ id: string, label: string, tooltip: string }>;
    itemDetail: Record<string, any>
}

export const ProductListTableItems: FC<ProductListTableItemsProps> = (props) => {
    const {
        count = 10,
        items = [],
        onPageChange = () => { },
        onRowsPerPageChange,
        page = 0,
        rowsPerPage = 10,
        headers = [],
        itemDetail
    } = props;

    const setNewMatch = useStoreActions((actions: any) => actions.opportunityDetail.setNewMatch);

    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<any>('itemname');

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: any,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangeMatch = (match_item: Record<string, any>) => {
        const data = {
            index: itemDetail['index'],
            newItemCode_match_sugerido: match_item['itemcode'],
            newItemName_match_sugerido: match_item['itemname'],
            newNombreProveedor_match: match_item['nombre_proveedor'],
            newCluster_match: null,
            newSimilitudCoseno_match: null,
            newScorePriorizacion_match: null,
            newEstimatedCost: match_item['precio_adquisicion_sin_iva'],
            newSellingPrice: match_item['precio_normal_sin_iva'],
            newCategoryRotation: match_item['categoria_rotacion'],
            newStock: match_item['available_clds'],
        }
        setNewMatch(data)
    }

    const visibleRows = useMemo(
        () =>
            stableSort(items, getComparator(order, orderBy)),
        [order, orderBy, items],
    );

    return (
        <Box>
            <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size='medium'
            >
                <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    headCells={headers}
                />

                <TableBody>
                    {visibleRows.map((row, index) => {
                        return (
                            <TableRow
                                hover
                                tabIndex={-1}
                                key={index}
                            >
                                <TableCell>
                                    <Checkbox 
                                        key={`chk-${index}`}
                                        aria-label='Checkbox demo'
                                        disableRipple
                                        onClick={() => handleChangeMatch(row)}
                                        checked = {row['itemcode'] === itemDetail['itemcode_match_sugerido']}
                                    />
                                </TableCell>
                                <TableCell align="left">{row.itemcode || '-'}</TableCell>
                                <TableCell align="left">{row.itemname || '-'}</TableCell>
                                <TableCell align="left">{row.nombre_proveedor || '-'}</TableCell>
                                <TableCell align="left">{row.categoria_rotacion || '-'}</TableCell>
                                <TableCell align="left">{row.available_clds || '-'}</TableCell>
                                <TableCell align="left">{ row.precio_adquisicion_sin_iva ? parseCurrency( row.precio_adquisicion_sin_iva) : '-'}</TableCell>
                                <TableCell align="left">{ row.precio_normal_sin_iva ? parseCurrency( row.precio_normal_sin_iva) : '-'}</TableCell>
                                <TableCell align="left">{row.firmname || '-'}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>

            <TablePagination
                component="div"
                count={count}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
            />
        </Box >
    );
};

ProductListTableItems.propTypes = {
    count: PropTypes.number,
    items: PropTypes.array,
    onPageChange: PropTypes.func,
    onRowsPerPageChange: PropTypes.func,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number
};
