import * as Yup from 'yup';
import { useFormik } from 'formik';
import ArrowLeftIcon from '@untitled-ui/icons-react/build/esm/ArrowLeft';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useStoreActions } from '../../store';
import { useState } from 'react';
import { Backdrop } from '@mui/material';
import { CircularLoader } from '../../components/circular-loader';
import StateRequestScreen from '../../components/state-request-screen';

interface Values {
    email: string;
    password: string;
    submit: null;
}

const initialValues: Values = {
    email: '',
    password: '',
    submit: null
};

const validationSchema = Yup.object({
    email: Yup
        .string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
    password: Yup
        .string()
        .max(255)
        .required('Password is required')
});

const Page = () => {

    const [loadingState, setLoadingState] = useState<null | 'loading' | 'not_found' | 'error'>(null)
    const [open, setOpen] = useState(false);


    const navigate = useNavigate();
    const login = useStoreActions((actions: any) => actions.auth.login);
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values): Promise<void> => {
            setOpen(true)
            setLoadingState('loading')

                const isAuthenticated = await login(values);

                if(isAuthenticated){
                    navigate('/dashboard');
                } else{
                    setLoadingState('not_found')
                }
        }
    });

    const handleClose = () => {
        if (loadingState !== 'loading') {
            setOpen(false);
        }
    }

    let backdrop_child = <></>
    switch (loadingState) {
        case 'loading':
            backdrop_child = <CircularLoader state='loading' />;
            break;
        case 'error':
            backdrop_child = <Box sx={{ width: '100%', maxWidth: '400px' }}><StateRequestScreen status='error' text='Error al iniciar sesión, inténtelo de nuevo más tarde.' /></Box>
            break;
        case 'not_found':
            backdrop_child = <Box sx={{ width: '100%', maxWidth: '400px' }}><StateRequestScreen status='error' text='El usuario y contraseña no coinciden.' /></Box>
            break;
        default:
            break;
    }

    return (
        <>
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={open}
                onClick={handleClose}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '50%',
                        height: '50%',
                        maxWidth: '500px',
                        backgroundColor: '#fff',
                        borderRadius: 2,
                        padding: '10px'
                    }}
                >
                    {backdrop_child}
                </Box>
            </Backdrop>

            <div>
                <Box sx={{ mb: 4 }}>
                    <Link
                        color="text.primary"
                        component={() => null}
                        href={"() => null"}
                        sx={{
                            alignItems: 'center',
                            display: 'inline-flex'
                        }}
                        underline="hover"
                    >
                        <SvgIcon sx={{ mr: 1 }}>
                            <ArrowLeftIcon />
                        </SvgIcon>
                        <Typography variant="subtitle2">
                            Dashboard
                        </Typography>
                    </Link>
                </Box>
                <Stack
                    sx={{ mb: 4 }}
                    spacing={1}
                >
                    <Typography
                        variant="h5"
                    >
                        Iniciar Sesión
                    </Typography>
                    {/*<Typography
                        color="text.secondary"
                        variant="body2"
                    >
                        Don&apos;t have an account?
                        &nbsp;
                        <Link
                            href="#"
                            underline="hover"
                            variant="subtitle2"
                        >
                            Register
                        </Link>
                    </Typography>*/}
                </Stack>
                <form
                    noValidate
                    onSubmit={formik.handleSubmit}
                >
                    <Stack spacing={3}>
                        <TextField
                            autoFocus
                            error={!!(formik.touched.email && formik.errors.email)}
                            fullWidth
                            helperText={formik.touched.email && formik.errors.email}
                            label="Email"
                            name="email"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            type="email"
                            value={formik.values.email}
                        />
                        <TextField
                            error={!!(formik.touched.password && formik.errors.password)}
                            fullWidth
                            helperText={formik.touched.password && formik.errors.password}
                            label="Contraseña"
                            name="password"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            type="password"
                            value={formik.values.password}
                        />
                    </Stack>
                    <Button
                        fullWidth
                        sx={{ mt: 3 }}
                        size="large"
                        type="submit"
                        variant="contained"
                    >
                        Continuar
                    </Button>
                    <Box sx={{ mt: 3 }}>
                        <Link
                            href="/recuperar-clave"
                            underline="hover"
                            variant="subtitle2"
                        >
                            ¿Olvidaste tu contraseña?
                        </Link>
                    </Box>
                </form>
            </div >
        </>
    );
};

export default Page;
