import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, FormGroup, TextField } from "@mui/material"
import { Box, BoxProps, Stack } from "@mui/system"
import { FC, useState, useCallback, useMemo, useEffect} from "react"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import parseStatus, { getStatusID, OpportunityStatusType } from '../utils/parseStatus';
import DatePickerRange from './date-picker-range';
import { useStoreState } from '../store';
import DemandIdOpportunityFilter from "./demand-id-opportunity-filter";
import DemandTotalSkuFilter from "./demand-total-sku-filter";

interface AccordionFiltersProps {
    readonly children: React.ReactNode,
    readonly title: string,
    readonly endpoint: string,
}

const AccordionFilters: FC<AccordionFiltersProps> = ({ children, title, endpoint }) => {

    return (
        <Accordion
            id="accordion_no_header_padding"
            sx={{
                border: '1px solid #f0f0f0',
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
                {title}
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    maxHeight: '300px',
                    overflowY: 'auto'
                }}
            >
                <FormGroup>
                    {children}
                </FormGroup>
            </AccordionDetails>
        </Accordion>
    )
}

interface FiltersDemandSideNavProps {
    readonly isFiltersOpen: boolean
    readonly filterOptions: any
    readonly onChangeFilters: any
    readonly sx?: BoxProps
}

const FiltersDemandSideNav: FC<FiltersDemandSideNavProps> = ({ isFiltersOpen, filterOptions, onChangeFilters, sx }) => {

    const [creationDate, setCreationDate] = useState({ 'start': null, 'end': null });
    const [expirationDate, setExpirationDate] = useState({ 'start': null, 'end': null })
    const activeFilters = useStoreState((state: any) => state.demand.activeFilters);

    useEffect(() => {
        if (!activeFilters?.create_date.start && !activeFilters?.create_date.end) {
                setCreationDate({ 'start': null, 'end': null });
            }
    }, [activeFilters?.create_date]);    

    useEffect(() => {
            if (!activeFilters?.expiration_date.start && !activeFilters?.expiration_date.end) {
                setExpirationDate({ 'start': null, 'end': null });
            }
    }, [activeFilters?.expiration_date]);    

    const handleFilterChange = useCallback((item: any, endpoint: string) => {
        onChangeFilters(item, endpoint);
    }, [onChangeFilters]);

    const portalOrigenSelectedValues = useMemo(
        () => {
            let selectedValues: any[] = [];
            if(activeFilters.portal_de_origen?.length > 0){
                selectedValues = activeFilters.portal_de_origen.map((item: any) => ({label: item}) );
            }
            return selectedValues
        },
        [activeFilters.portal_de_origen],
      );

    const nombreClienteSelectedValues = useMemo(
        () => {
            let selectedValues: any[] = [];
            if(activeFilters.nombre_cliente?.length > 0){
                selectedValues = activeFilters.nombre_cliente.map((item: any) => ({label: item}) );
            }
            return selectedValues
        },
        [activeFilters.nombre_cliente],
      );

    const statusSelectedValues = useMemo(
        () => {
            let selectedValues: any[] = [];
            if(activeFilters.status?.length > 0){
                selectedValues = activeFilters.status.map((item: any) => ({label: parseStatus(item)}) );
            }
            return selectedValues
        },
        [activeFilters.status],
      );

    return <Box
        sx={{
            ...sx,
            height: '100%',
            overflowY: 'auto',
            display: isFiltersOpen ? 'block' : 'none',
        }}
    >
        {
            filterOptions &&
            <Stack>
                <AccordionFilters
                    title={filterOptions['portal_de_origen']['label']}
                    endpoint={'portal_de_origen'}
                    children={
                        <Autocomplete
                            multiple
                            filterSelectedOptions
                            value={portalOrigenSelectedValues}
                            id="portal_de_origen"
                            options={filterOptions['portal_de_origen']['items'].map((label: string) => ({ label }))}
                            noOptionsText='No se encontraron resultados'
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(event: any, newValues: Array<string> | null) => {
                                onChangeFilters(newValues?.map((item: any) => (item.label)), 'portal_de_origen')
                            }}
                        />
                    }
                />
                <AccordionFilters
                    title={filterOptions['nombre_cliente']['label']}
                    endpoint={'nombre_cliente'}
                    children={
                        <Autocomplete
                            multiple
                            filterSelectedOptions
                            value={nombreClienteSelectedValues}
                            id="nombre_cliente"
                            options={filterOptions['nombre_cliente']['items'].map((label: string) => ({ label }))}
                            noOptionsText='No se encontraron resultados'
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(event: any, newValues: Array<string> | null) => {
                                onChangeFilters(newValues?.map((item: any) => (item.label)), 'nombre_cliente')
                            }}
                        />
                    }
                />
                <AccordionFilters
                    title={filterOptions['status']['label']}
                    endpoint={'status'}
                    children={
                        <Autocomplete
                            multiple
                            filterSelectedOptions
                            value={statusSelectedValues}
                            id="status"
                            options={filterOptions['status']['items'].map((label: OpportunityStatusType) => ({ label: parseStatus(label) }))}
                            noOptionsText='No se encontraron resultados'
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(event: any, newValues: Array<string> | null) => {
                                onChangeFilters(newValues?.map((item: any) => (getStatusID(item.label))), 'status')
                            }}
                        />
                    }
                />
                <AccordionFilters
                    title={'ID Oportunidad'}
                    endpoint={'id_opportunity'}
                    children={
                        <DemandIdOpportunityFilter onSubmitIdOpportunity={handleFilterChange} />
                    }
                />
                <AccordionFilters
                    title={'Fecha de Creación'}
                    endpoint={'create_date'}
                    children={
                        <DatePickerRange
                            dates={creationDate}
                            onChangeDate={(newDate) => { setCreationDate(newDate) }}
                            onClickFab={() => onChangeFilters(creationDate, 'create_date')}
                        />
                    }
                />
                <AccordionFilters
                    title={'Fecha de Vencimiento'}
                    endpoint={'expiration_date'}
                    children={
                        <DatePickerRange
                            dates={expirationDate}
                            onChangeDate={setExpirationDate}
                            onClickFab={() => onChangeFilters(expirationDate, 'expiration_date')}
                        />
                    }
                />

                <AccordionFilters
                    title={'Total SKU'}
                    endpoint={'total_sku'}
                    children={
                        <DemandTotalSkuFilter onSubmitTotalSKU={handleFilterChange} />
                    }
                />
            </Stack>
        }
    </Box>
}

export default FiltersDemandSideNav;