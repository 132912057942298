import { Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { useStoreState, useStoreActions } from '../store';
import React, { useEffect } from 'react';
import parseCurrency from '../utils/parseCurrency';

function DemandKPIContainer() {

  const requestKPI = useStoreActions((actions: any) => actions.demand.requestKPI);
  const KPI = useStoreState((state: any) => state.demand.KPI);

  useEffect(() => {
          requestKPI()
  }, [requestKPI]);

  return (
    <Box marginTop='20px'>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        textAlign='center'
        color='#909194'
        sx={{
          border: '1px solid #f0f0f0',
          width: 'fit-content',
          margin: 'auto',
          borderRadius: '10px',
        }}
      >
        {
          KPI &&
            KPI.map((item: Record<string, string>, index: number) => (
              <Box
                key={index}
                sx={{
                  height: '100%',
                  padding: '20px',
                  ...(  index !== KPI.length - 1 &&
                    {
                      backgroundImage: 'linear-gradient(#909194 33%, rgba(255,255,255,0) 0%);',
                      backgroundPosition: 'right;',
                      backgroundSize: '1px 15px;',
                      backgroundRepeat: 'repeat-y;'
                    }
                  )
                }}
              >
                <Typography variant="h2">{ (item.title === 'Volumen Pedidos Ofertados' || item.title === 'Promedio Potencial Valorizado') ? parseCurrency(item.value) || '0': parseFloat(item.value).toLocaleString('en-US') }</Typography>
                <Typography variant="h6">{item.title}</Typography>
                <Typography variant="subtitle2" marginTop='10px'>Últimos 30 días</Typography>
              </Box>
            ))
        }
      </Stack>
    </Box>
  )
}

export default DemandKPIContainer