import { FC } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { es } from "date-fns/locale";

interface DatePickerProps {
    readonly date: any;
    readonly setDate: (date: any) => void;
    readonly label: string;
    readonly inputProps?: any;
    readonly sx?: any;
    readonly error?: boolean;
}

const parseDate = (dateStr: string | null): Date | null => {
    if (!dateStr) return null;
    const parsedDate = new Date(dateStr);
    return isNaN(parsedDate.getTime()) ? null : parsedDate;
};

const CustomDatePicker: FC<DatePickerProps> = ({ date, setDate, label, inputProps, sx, error = false }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
            <DatePicker
                label={label}
                value={parseDate(date)}
                onChange={(newDate) => setDate(newDate ? newDate.toISOString().split("T")[0] : null)}
                format="dd/MM/yyyy"
                slotProps={{
                    textField: {
                        error: error,
                        helperText: error ? "Campo Obligatorio" : "",
                        size: "small",
                        InputLabelProps: { shrink: true },
                        inputProps: { ...inputProps },
                        sx: { ...sx },
                    },
                }}
            />
        </LocalizationProvider>
    );
};
export default CustomDatePicker;