import { action, Action, Thunk, thunk } from 'easy-peasy';
import axios from '../axiosConfig';

interface ProductDetailModel {
    productDetail: Record<any, any> | null;
    productStock: Record<any, any> | null;
    productDetailImages: Record<any, any> | null;
    
    requestSearchState: null | 'loading' | 'error' | 'success';
    requestSearchStateImages: null | 'loading' | 'error' | 'success';

    requestProductDetail: Thunk<ProductDetailModel, string>;
    requestProductDetailImages: Thunk<ProductDetailModel, string>;

    setRequestSearchState: Action<ProductDetailModel, null | 'loading' | 'error' | 'success'>;
    setRequestSearchStateImages: Action<ProductDetailModel, null | 'loading' | 'error' | 'success'>;
    
    setProductDetail: Action<ProductDetailModel, [] | null>;
    setProductStock: Action<ProductDetailModel, [] | null>;
    setProductDetailImages: Action<ProductDetailModel, [] | null>;

}

const productDetailModel: ProductDetailModel = {
    productDetail: null,
    productStock: null,
    productDetailImages: null,

    requestSearchState: null,
    requestSearchStateImages: null,

    setProductDetail: action((state, payload) => {
        state.productDetail = payload;
    }),

    setProductStock: action((state, payload) => {
        state.productStock = payload;
    }),

    setProductDetailImages: action((state, payload) => {
        state.productDetailImages = payload;
    }),

    setRequestSearchState: action((state, payload) => {
        state.requestSearchState = payload;
    }),

    setRequestSearchStateImages: action((state, payload) => {
        state.requestSearchStateImages = payload;
    }),

    requestProductDetail: thunk(async (actions, itemcode: string) => {
        try {
            actions.setRequestSearchState('loading')
            actions.setProductDetail(null)
            
            const response = await axios.get(`/search/product-detail/${itemcode}`, {timeout: 120000});
            
            const {detail, stock} = response.data['data']

            actions.setProductDetail(detail);
            actions.setProductStock(stock);
            
            actions.setRequestSearchState(response.status === 200 ? 'success' : 'error')
            return true;
        } catch (error) {
            actions.setRequestSearchState('error')
            console.error('Error fetching users:', error);
            return false;
        }

    }),

    requestProductDetailImages: thunk(async (actions, itemcode: string) => {
        try {
            actions.setRequestSearchStateImages('loading')
            actions.setProductDetailImages(null)
            
            const response = await axios.get(`/search/list_images/${itemcode}`);
            
            actions.setProductDetailImages(response.data['data']);
            actions.setRequestSearchStateImages(response.status === 200 ? 'success' : 'error')
            return true;
        } catch (error) {
            actions.setRequestSearchStateImages('error')
            console.error('Error fetching users:', error);
            return false;
        }

    }),
};

export default productDetailModel;