import { Chip } from "@mui/material";
import { FC } from "react";

const rotationOrder: string[] = ['A', 'B', 'C', 'N', 'CNI', 'INM6', 'INM12', 'INM', 'NULL', 'UR'];

const rotationColors: Record<string, string> = {
    A: "#2ECC71",    // Verde (Alta prioridad)
    B: "#58D68D",    // Verde claro
    C: "#F1C40F",    // Amarillo
    N: "#F39C12",    // Naranja suave
    CNI: "#E67E22",  // Naranja oscuro
    INM6: "#E74C3C", // Rojo claro
    INM12: "#C0392B", // Rojo fuerte
    INM: "#7F8C8D",   // Gris oscuro
    NULL: "#BDC3C7",  // Gris claro
    UR: "#2C3E50",    // Negro / Azul oscuro
  };

export const getRotationCategory = (rotation_category: string) : string => { 
    const rotationSplit = rotation_category.split('-');
    rotationSplit.sort((a, b) => rotationOrder.indexOf(a) - rotationOrder.indexOf(b));
    return rotationSplit[0];
}

interface RotationChipProps {
  readonly rotation_category: string | null;
  readonly size?: 'small' | 'medium';
}

export const RotationChip : FC<RotationChipProps> = ({rotation_category, size = 'medium'}) => {
  if (!rotation_category) return null;

  const rotation = getRotationCategory(rotation_category)
  return (
    <Chip
    size={size}
    label={rotation_category}
    sx={{
      backgroundColor: rotationColors[rotation] || "#CCCCCC",
      color: "#fff",
      fontWeight: "bold",
      ...(
      size === 'small' && {
        padding: '10px 0px',
        borderRadius: '5px',
        height: '14px'
      }
      )
    }}
    />
  );
};