import { FC, useState, useEffect } from 'react'
import { Box } from '@mui/system'
import Image01Icon from '@untitled-ui/icons-react/build/esm/Image01';
import SvgIcon from '@mui/material/SvgIcon';
import { Skeleton } from '@mui/material';

import axios from '../axiosConfig';

interface ProductImageProps {
    readonly itemcode: string | number
    readonly link_imagen: string | number | null
    readonly width: string | number
    readonly height: string | number
}

const ErrorImage: FC = () => <Box
    sx={{
        alignItems: 'center',
        borderRadius: 1,
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%'
    }}
>
    <SvgIcon>
        <Image01Icon />
    </SvgIcon>
</Box>

const LoadingSkeleton: FC = () => <Skeleton variant="rectangular" width={'100%'} height={'100%'} animation="wave" />

const requestProductImage = async (itemcode: string | number, signal: any) => {
    let response = null
    try {
        response = await axios.get(`/search/image/${itemcode}`, {signal});
        response = response.data['data']
    }
    catch {
        console.error(response);
    }

    return response;
}


const ProductImage: FC<ProductImageProps> = ({ itemcode, width, height, link_imagen }) => {

    const [requestState, setRequestState] = useState<null | 'loading' | 'success' | 'error'>('loading')
    const [image, setImage] = useState<null | string>()
    
    useEffect(() => {
        const abortController = new AbortController();

        if(link_imagen){
            setRequestState('success')
            return
        }
        if (itemcode && !link_imagen) {
            setRequestState('loading')
            requestProductImage(itemcode, abortController.signal)
                .then((response) => {
                    if (!abortController.signal.aborted) {
                        setRequestState('success')
                        setImage(response)
                    }
                })
                .catch((error) => {
                    if (!abortController.signal.aborted) {
                        setRequestState('error')
                    }
                })
        }

        return () => {
            abortController.abort();
        };
    }, [itemcode, link_imagen])


    if(requestState === 'loading') return <Box width={width} height={height} ><LoadingSkeleton /></Box>

    if(link_imagen) return (
        <Box
            sx={{
                alignItems: 'center',
                backgroundImage: `url(${link_imagen})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                borderRadius: 1,
                display: 'flex',
                height: {height},
                justifyContent: 'center',
                overflow: 'hidden',
                width: {width}
            }}
        />
    )

    if(itemcode) return (
        <Box
            sx={{
                alignItems: 'center',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'center',
                overflow: 'hidden',
            }}
            component='img'
            src={`data:image/png;base64, ${image}`}
            width={width}
            height={height}
        />
    )

    return <Box width={width} height={height}><ErrorImage /></Box>

}

export default ProductImage