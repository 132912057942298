import React, { lazy } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";
import { useStoreState, useStoreActions } from './store';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import LoginPage from "./pages/auth/login"
import ForgotPassword from "./pages/auth/forgotPassword"
import UpdatePassword from "./pages/auth/updatePassword"
import { createTheme } from './theme';
import { Layout as AuthLayout } from './layouts/classic-layout';
import { Layout as DashboardLayout } from './layouts/dashboard/layout';
import ProductDetail from './pages/product-detail';
import { ProductMaster } from './pages/product-master';
import DemandPage from './pages/demand';
import { Binnacles as BinnaclesPage } from './pages/binnacles';

import OpportunityDetailPage from './pages/opportunity-detail';

const UserListPage = lazy(() => import('./pages/users'));


// Componente de ruta protegida
const ProtectedRoute: React.FC<{ element: JSX.Element }> = ({ element }) => {

  const isAuthenticated = useStoreState((state: any) => state.auth.isAuthenticated);
  return isAuthenticated ? element : <Navigate to="/login" replace />;
};



const Users: React.FC = () => <DashboardLayout><UserListPage /></DashboardLayout>;
const ProductDetailPage: React.FC = () => <DashboardLayout><ProductDetail /></DashboardLayout>;
const ProductMasterPage: React.FC = () => <DashboardLayout><ProductMaster /></DashboardLayout>;
const Demand: React.FC = () => <DashboardLayout><DemandPage /></DashboardLayout>;
const OpportunityDetail: React.FC = () => <DashboardLayout><OpportunityDetailPage /></DashboardLayout>;
const _Binnacles: React.FC = () => <DashboardLayout><BinnaclesPage /></DashboardLayout>;

const Login: React.FC = () => {
  const isAuthenticated = useStoreState((state: any) => state.auth.isAuthenticated);
  return isAuthenticated ? <Navigate to="/dashboard" replace /> : <AuthLayout>
    <LoginPage />
  </AuthLayout>;
};
const ForgotPasswordFunc: React.FC = () => {
  const isAuthenticated = useStoreState((state: any) => state.auth.isAuthenticated);
  return isAuthenticated ? <Navigate to="/dashboard" replace /> : <AuthLayout>
    <ForgotPassword />
  </AuthLayout>;
};

// Definir las rutas con tipos
const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/recuperar-clave",
    element: <ForgotPasswordFunc />,
  },
  {
    path: "/actualizar-clave",
    element: <UpdatePassword />,
  },
  {
    path: "/dashboard",
    element: <ProtectedRoute element={<ProductMasterPage />} />,
  },
  {
    path: "/users",
    element: <ProtectedRoute element={<Users />} />,
  },
  {
    path: "/detalle-producto",
    element: <ProtectedRoute element={<ProductDetailPage />} />,
  },
  {
    path: "/maestra-productos",
    element: <ProtectedRoute element={<ProductMasterPage />} />,
  },
  {
    path: "/demanda",
    element: <ProtectedRoute element={<Demand />} />,
  },
  {
    path: "/detalle-oportunidad",
    element: <ProtectedRoute element={<OpportunityDetail />} />,
  },
  {
    path: "/bitacora",
    element: <ProtectedRoute element={<_Binnacles />} />,
  },
]);

const App: React.FC = () => {
  const theme = createTheme({ colorPreset: 'red' })
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
