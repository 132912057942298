import type { FC } from 'react';
import { useState } from 'react'
import PropTypes from 'prop-types';
import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01';
import { alpha } from '@mui/system/colorManipulator';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { Theme } from '@mui/material/styles/createTheme';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchMdIcon from '@untitled-ui/icons-react/build/esm/SearchMd';

import { AccountButton } from '../account-button';
import { useStoreActions } from '../../../store';
import { Logo } from '../../../components/logo';
import { usePathname } from '../../../hooks/use-pathname';

const TOP_NAV_HEIGHT = 64;

interface TopNavProps {
  onMobileNavOpen?: () => void;
  sideNavOpen: any;
}

export const TopNav: FC<TopNavProps> = (props) => {
  const [searchText, setSearchText] = useState('');
  const setProductToSearch = useStoreActions((actions: any) => actions.productMaster.setProductToSearch);
  const pathname = usePathname();
  const { onMobileNavOpen, sideNavOpen, ...other } = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const placeholdersSearch = ["Tornillos 3 pulgadas", "Taladro percutor", "Varilla de cobre", "Acero galvanizado", "Fusible solar", "Cable de cobre 100mtrs", "ZUNCHO PLASTICO"]
  const randonValuePlaceholder = Math.floor(Math.random() * placeholdersSearch.length);
  const placeholderSearch = placeholdersSearch[randonValuePlaceholder]

  const submitHandler = (e: any) => {
    e.preventDefault()
    setProductToSearch(searchText)
  }

  return (
    <Box
      component="header"
      sx={{
        backdropFilter: 'blur(15px)',
        backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
        borderRadius: '10px',
        position: 'fixed',
        left: {
          lg: sideNavOpen.sideNavWidth
        },
        marginTop: '1%',
        width: {
          lg: `calc(100% - ${sideNavOpen.sideNavWidth})`
        },
        zIndex: (theme) => theme.zIndex.appBar,
        transition: 'left 700ms cubic-bezier(0.2,0,0,1) 0s'
      }}
      {...other}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: 2
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
        >
          {!lgUp && (
            <IconButton onClick={onMobileNavOpen}>
              <SvgIcon>
                <Menu01Icon />
              </SvgIcon>
            </IconButton>
          )}
          {
            !sideNavOpen.isSideNavOpen &&
            <Box
              sx={{
                display: 'flex',
                p: 0,
                height: 'fit-content',
                width: '100px',
              }}
            >
              <Logo />
            </Box>
          }
          {
            (pathname === "/dashboard" || pathname === "/maestra-productos") &&
            <Box component="form" onSubmit={(e) => submitHandler(e)} sx={{ width: '50em' }}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon>
                        <SearchMdIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                label="Buscador"
                onChange={(event): void => setSearchText(event.target.value)}
                placeholder={placeholderSearch}
                value={searchText}
              />
            </Box>
          }
        </Stack>
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
        >
          <AccountButton />
        </Stack>
      </Stack>
    </Box>
  );
};

TopNav.propTypes = {
  onMobileNavOpen: PropTypes.func
};
