import { Alert } from '@mui/material';
import {FC} from 'react';
import { Product } from '../types/product';

interface StockAlertsProps {
    stock: Product['available_clds'];
}

const StockAlerts: FC<StockAlertsProps> = ({stock}) => {

    if(stock === null){
        return (
            <Alert
                severity='info'
                sx={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    textAlign: 'center',
                    color: 'black',
                    '.MuiAlert-icon': {
                        margin: 0,
                        padding: 0
                    }
                }}
            >
                Sin Información
            </Alert>
        );
    }

    return (
            stock <= 0 ? 
                <Alert
                    severity='error'
                    sx={{
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        textAlign: 'center',
                        color: 'black',
                        '.MuiAlert-icon': {
                            margin: 0,
                            padding: 0
                        }
                    }}
                >
                    Sin Stock
                </Alert>
            : <>{stock}</>
        
    );
};

export default StockAlerts;