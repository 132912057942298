const parseCurrency = (currency: string | number | null) : string | null => {
    return currency ? currency.toLocaleString(
        'en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        useGrouping: true,
    }).replace(/,/g, '.') : null
}

export default parseCurrency;