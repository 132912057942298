import { useState, useEffect, memo } from "react"
import { useStoreState } from '../store';
import { Fab, TextField } from "@mui/material"
import { Stack, Box } from "@mui/system"
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const DemandTotalSkuFilter = memo(({ onSubmitTotalSKU }: { onSubmitTotalSKU: (item: any, endpoint: string) => void }) => {
    const activeFilters = useStoreState((state: any) => state.demand.activeFilters);
    const [totalSKU, setTotalSKU] = useState<{min: number | null, max: number | null}>({min: null, max: null});

    useEffect(() => {
        if (!activeFilters?.totalSKU) {
            setTotalSKU({min: null, max: null});
        }
    }, [activeFilters?.totalSKU]);

    return (
            <Stack
                component='form'
                onSubmit={
                    (event: any) => { 
                        event.preventDefault();
                        onSubmitTotalSKU(
                            {
                                'min': event.currentTarget.elements.minTotalSKU.value === "" ? null : parseInt(event.currentTarget.elements.minTotalSKU.value), 
                                'max': event.currentTarget.elements.maxTotalSKU.value === "" ? null : parseInt(event.currentTarget.elements.maxTotalSKU.value)
                            },
                            'totalSKU'
                        )
                    }}
            >
                {/*<Button variant='text' onClick={() => { onChangeFilters({ 'min': '0', 'max': '2' }, 'totalSKU') }}> Hasta 2 SKUs </Button>
                                <Button variant='text' onClick={() => { onChangeFilters({ 'min': '3', 'max': '5' }, 'totalSKU') }}> Entre 3 y 5 SKUs </Button>
                                <Button variant='text' onClick={() => { onChangeFilters({ 'min': '6', 'max': null }, 'totalSKU') }}> Más de 6 SKUs </Button>*/}
                <Stack
                    direction='row'
                    spacing={2}
                    alignItems='center'
                >
                    <TextField
                        type='number'
                        id='minTotalSKU'
                        label="Min"
                        variant="standard"
                        value={totalSKU.min || ''}
                        onChange={(e) => setTotalSKU( (currentState) => ({
                                min: parseInt(e.target.value) > 0 ? parseInt(e.target.value) : null, 
                                max: currentState.max
                            }) 
                        )}
                    />

                    <TextField
                        type='number'
                        id='maxTotalSKU'
                        label="Max"
                        variant="standard"
                        value={totalSKU.max || ''}
                        onChange={(e) => setTotalSKU( (currentState) => ({
                                min: currentState.min, 
                                max: parseInt(e.target.value) > 0 ? parseInt(e.target.value) : null 
                            })
                        )}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            margin: 'auto',
                            height: '100%',
                        }}
                    >
                        <Fab color="primary" aria-label="edit" size='small' type='submit'
                            sx={{
                                width: '24px',
                                height: '24px',
                                minHeight: '24px',
                            }} >
                            <KeyboardArrowRightIcon />
                        </Fab>
                    </Box>
                </Stack>
            </Stack>
    );
});

export default DemandTotalSkuFilter;