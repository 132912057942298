import { Button, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React, { FC, useEffect, MouseEvent, ChangeEvent, useState, useCallback, useMemo } from 'react'
import { useStoreState, useStoreActions } from '../../store';
import { CircularLoader } from '../../components/circular-loader';
import { ProductListTable } from '../../components/list-table-products';
import StateRequestScreen from '../../components/state-request-screen';
import { useIsMount } from '../../hooks/use-is-mount';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { ProductListGrid } from '../../components/grid-table-products';
import Chip from '@mui/material/Chip';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import IconButton from '@mui/material/IconButton';
import FiltersSideNav from '../../components/filers-side-nav';

interface ProductMasterProps {

}

export const ProductMaster: FC<ProductMasterProps> = () => {

  const requestFilterOptions = useStoreActions((actions: any) => actions.productMaster.requestFilterOptions);
  const requestProductsInPage = useStoreActions((actions: any) => actions.productMaster.requestProductsInPage);
  const setRowsPerPage = useStoreActions((actions: any) => actions.productMaster.setRowsPerPage);
  const setPage = useStoreActions((actions: any) => actions.productMaster.setPage);
  const setRequestTableSearchState = useStoreActions((actions: any) => actions.productMaster.setRequestTableSearchState);
  const setProductToSearch = useStoreActions((actions: any) => actions.productMaster.setProductToSearch);
  const setActiveFilters = useStoreActions((actions: any) => actions.productMaster.setActiveFilters);
  const setProductsView = useStoreActions((actions: any) => actions.productMaster.setProductsView);

  const activeFilters = useStoreState((state: any) => state.productMaster.activeFilters);
  const filterOptions = useStoreState((state: any) => state.productMaster.filterOptions);
  const requestSearchState = useStoreState((state: any) => state.productMaster.requestSearchState);
  const requestTableSearchState = useStoreState((state: any) => state.productMaster.requestTableSearchState);
  const productToSearch = useStoreState((state: any) => state.productMaster.productToSearch);
  const productsInPage = useStoreState((state: any) => state.productMaster.productsInPage);
  const page = useStoreState((state: any) => state.productMaster.page);
  const rowsPerPage = useStoreState((state: any) => state.productMaster.rowsPerPage);
  const productsView = useStoreState((state: any) => state.productMaster.productsView);

  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);

  const isMount = useIsMount();

  const controller = new AbortController();

  const onChangeFilters = useCallback((data: Record<string, any>, checked: boolean) => {
    if(data.endpoint !== 'order'){
      setActiveFilters({endpoint: 'order', item: {order: null, orderBy: null} })
    }

    setPage(1)

    setActiveFilters({...data, checked})

  }, [setRequestTableSearchState, setPage, setActiveFilters, setProductToSearch])

  const limpiarFiltros = useCallback(() => {
    setActiveFilters(null)
    setProductToSearch(null)
    setPage(1)
  }, [setActiveFilters, setProductToSearch, setPage])

  const getFiltersList = useCallback(() => {
    let filters: Record<string, string> = {}

    Object.keys(activeFilters).forEach(function (key, index) {
      if (key !== 'order') {
        filters[key] = activeFilters[key].map((item: any) => item.id);
      } else {
        filters[key] = activeFilters[key];
      }
    });

    return filters
  }, [activeFilters])

  useEffect(() => {
    requestProductsInPage({})
    requestFilterOptions()
  }, [requestProductsInPage, requestFilterOptions]);

  useEffect(() => {
    if (productToSearch) {
      onChangeFilters({ item: { value: productToSearch, id: productToSearch }, endpoint: 'productToSearch' }, true)
    }
  }, [productToSearch, onChangeFilters]);

  useEffect(() => {
    if (isMount) {
      const filters = getFiltersList()
      requestProductsInPage({ filters })
    }
  }, [rowsPerPage, page]);

  useEffect(() => {
    if (isMount) {
      const filters = getFiltersList()
      requestProductsInPage({ filters, description: productToSearch, signal: controller.signal })
      return () => controller.abort();
    }
  }, [activeFilters]);

  const handlePageChange = useCallback((event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPage(newPage + 1)
  }, [setPage])

  const handleRowsPerPageChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    setRowsPerPage(event.target.value)
  }, [setRowsPerPage])

  const handleProductsViewChange = useCallback((event: React.MouseEvent<HTMLElement>, nextView: 'list' | 'grid') => {
    nextView && setProductsView(nextView);
  }, [setProductsView]);

  const submitHandler = useCallback((e: any) => {
    e.preventDefault()
    const value = e.target.item_name_search.value || ''
    onChangeFilters({ item: { value, id: value }, endpoint: 'getItemName' }, true)
  }, [onChangeFilters])

  const areActiveFilters = useMemo(
    () => Object.keys(activeFilters).some((key) => activeFilters[key].length > 0)
    , [activeFilters])

  const tableHeaders = useMemo(() => [
    { id: 'itemname', label: 'Nombre', tooltip: "N/A" },
    { id: 'CardName', label: 'Proveedor', tooltip: "N/A" },
    { id: 'available_clds', label: 'Stock', tooltip: "Stock físico en CLD para Serviall. Stock página web para Proveedores" },
    { id: 'firmname', label: 'Marca', tooltip: "N/A" },
    { id: 'suppcatnum', label: 'Número de Parte (P/N)', tooltip: "N/A" },
    { id: 'categoria_rotacion', label: 'Rotación', tooltip: "Indica la frecuencia de venta del producto (en meses). Primera letra considera últimos 3 meses, segunda letra considera último año." },
    { id: 'precio_adquisicion_sin_iva', label: 'Costo', tooltip: "Corresponde al precio de adquisición del articulo" },
  ], [])

  if (requestSearchState === 'loading') return (
    <CircularLoader state={requestSearchState} />
  )

  if (requestSearchState === 'error') return (
    <Box sx={{ width: '40%', display: 'flex', alignSelf: 'center', margin: 'auto', height: '100%' }}>
      <StateRequestScreen text='No es posible listar los productos en estos momentos' status='error' />
    </Box>
  )

  return (
    <Box
      sx={{
        height: '100%',
        position: 'relative'
      }}
    >
      <Stack
        gap={2}
        direction='row'
        justifyContent="space-around"
        sx={{
          
          height: '100%',
          padding: '20px 10px',
        }}
      >
        <FiltersSideNav
          isFiltersOpen={isFiltersOpen}
          filterOptions={filterOptions}
          activeFilters={activeFilters}
          submitHandler={submitHandler}
          onChangeFilters={onChangeFilters}
          sx={{
            minWidth: '20%'
          }}
        />
        <Box
          sx={{
            backgroundColor: 'white',
            padding: '10px',
            borderRadius: '10px',
            width: '100%',
            height: '100%'
          }}
        >
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            sx={{
              marginBottom: '10px'
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {
                (requestTableSearchState === 'success' && productsInPage) &&
                  <IconButton
                    aria-label="filtrar"
                    sx={{
                      borderRadius: '5px'
                    }}
                    onClick={() => setIsFiltersOpen(!isFiltersOpen)}
                  >
                    <FilterAltIcon />
                    <Typography variant='body1' >Filtrar</Typography>
                  </IconButton>
              }
              <Box>
                {
                  Object.keys(activeFilters).map((key: string) => (
                    (key !== 'order' && key !== 'orderBy') &&
                    activeFilters[key].map((item: Record<string, any>, idx: number) => (
                      <Chip
                        label={item.value}
                        color='primary'
                        variant="outlined"
                        key={item.id}
                        onDelete={() => onChangeFilters({ item, endpoint: key }, false)}
                      />
                    )
                    )
                  )
                  )
                }
                {areActiveFilters && <Button variant='text' onClick={limpiarFiltros}> Limpiar Filtros </Button>}
              </Box>
            </Stack>
            {
              productsInPage?.Datos.length > 0 &&
                <ToggleButtonGroup
                  value={productsView}
                  exclusive
                  onChange={handleProductsViewChange}
                  color="primary"
                  sx={{
                    width: 'fit-content',
                  }}
                >
                  <ToggleButton value="list" aria-label="list">
                    <ViewListIcon />
                  </ToggleButton>
                  <ToggleButton value="grid" aria-label="grid">
                    <ViewModuleIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
            }
          </Stack>
          {
            (requestTableSearchState === 'success' && productsInPage) ? (
              productsView === 'list' ?
                <ProductListTable
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  onChangeFilters={onChangeFilters}
                  headers={tableHeaders}
                  activeFilters={activeFilters}
                  items={productsInPage['Datos']}
                  count={productsInPage['TotalRecords']}
                  page={page - 1}
                  rowsPerPage={rowsPerPage}
                />
                :
                <ProductListGrid
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  page={page - 1}
                  items={productsInPage['Datos']}
                  count={productsInPage['TotalRecords']}
                  rowsPerPage={rowsPerPage}
                />
            )
            : <Box height='75%'>
              <CircularLoader state={requestTableSearchState} />
            </Box> 
          }
        </Box>
      </Stack>
    </Box>
  )
}