import * as Yup from 'yup';
import { useFormik } from 'formik';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useMounted } from '../../hooks/use-mounted';
import { Container } from '@mui/system';
import { useLocation } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreActions } from '../../store';
import { Backdrop } from '@mui/material';
import { CircularLoader } from '../../components/circular-loader';
import StateRequestScreen from '../../components/state-request-screen';


interface Values {
  code: string;
  email: string;
  password: string;
  passwordConfirm: string;
  submit: null;
}

const getInitialValues = (): Values => ({
  code: '',
  email: '',
  password: '',
  passwordConfirm: '',
  submit: null
});

const validationSchema = Yup.object({
  password: Yup
    .string()
    .max(255)
    .required('Campo obligatorio'),
  passwordConfirm: Yup
    .string()
    .oneOf([Yup.ref('password')], 'Las contraseñas no coinciden')
    .required('Campo obligatorio')
});

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const Page = () => {
  const isMounted = useMounted();
  const updatePassword = useStoreActions((actions: any) => actions.auth.updatePassword);

  const [loadingState, setLoadingState] = useState<null | 'loading' | 'success' | 'error'>(null)
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  let query = useQuery();

  if (query.get('access_token') === null) {
    navigate('/login');
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(),
    validationSchema,
    onSubmit: async (values, helpers): Promise<void> => {
      setOpen(true);
      setLoadingState('loading');
      try {
        if (isMounted()) {
          const response = await updatePassword({ password: values.password, token: query.get('access_token') });
          if (response) {
            setLoadingState('success');
          } else {
            setLoadingState('error');
          }
        }
      } catch (err: any) {
        console.error(err);
        setLoadingState('error');

        if (isMounted()) {
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err.message });
          helpers.setSubmitting(false);
        }
      }
    }
  });

  const handleClose = () => {
    if (loadingState !== 'success') {
      setOpen(false);
    }
  };

  let backdrop_child = <></>
  switch (loadingState) {
    case 'loading':
      backdrop_child = <CircularLoader state='loading' />;
      break;
    case 'error':
      backdrop_child = <Box sx={{ width: '100%', maxWidth: '400px' }}><StateRequestScreen status='error' text='Error al actualizar la contraseña, inténtelo de nuevo más tarde.' /></Box>
      break;
    case 'success':
      backdrop_child = <Stack spacing={4} sx={{ width: '100%', maxWidth: '400px' }}>
        <StateRequestScreen status='success' text='Contraseña actualizada correctamente' />
        <Button variant='text' onClick={() => navigate('/login')}>Iniciar Sesión</Button>
      </Stack>
      break;
    default:
      break;
  }

  return (
    <>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={open}
        onClick={handleClose}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '50%',
            height: '50%',
            backgroundColor: '#fff',
            borderRadius: 2,
            padding: '10px',
            maxWidth: '450px'
          }}
        >
          {backdrop_child}
        </Box>
      </Backdrop>

      <Container
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          minWidth: '100vw',
          backgroundColor: '#f0f0f0'
        }}
      >
        <Card
          elevation={16}
          sx={{
            width: "50%"
          }}
        >
          <CardHeader
            title="Restablecer Contraseña"
          />
          <CardContent>
            <form
              noValidate
              onSubmit={formik.handleSubmit}
            >
              <Stack spacing={3}>
                <TextField
                  error={!!(formik.touched.password && formik.errors.password)}
                  fullWidth
                  helperText={formik.touched.password && formik.errors.password}
                  label="Contraseña"
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="password"
                  value={formik.values.password}
                />
                <TextField
                  error={!!(formik.touched.passwordConfirm && formik.errors.passwordConfirm)}
                  fullWidth
                  helperText={formik.touched.passwordConfirm && formik.errors.passwordConfirm}
                  label="Repetir Contraseña"
                  name="passwordConfirm"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="password"
                  value={formik.values.passwordConfirm}
                />
              </Stack>
              {formik.errors.submit && (
                <FormHelperText
                  error
                  sx={{ mt: 3 }}
                >
                  {formik.errors.submit as string}
                </FormHelperText>
              )}
              <Button
                disabled={formik.isSubmitting}
                fullWidth
                size="large"
                sx={{ mt: 3 }}
                type="submit"
                variant="contained"
              >
                Cambiar Contraseña
              </Button>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  mt: 3
                }}
              >
              </Box>
            </form>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};
export default Page;
