import { Accordion, AccordionDetails, AccordionSummary, Button, Link, Modal, Tooltip, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system';
import React, { useState } from 'react'
import { Product } from '../types/product';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';

interface ModalProductEquivalencesProps {
    readonly product: Product | null;
}

export const ModalProductEquivalences: React.FC<ModalProductEquivalencesProps> = ({ product }) => {

    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>, row: Product) => {
        event.stopPropagation();
        setIsOpen(true);
    };
    
    const handleOnClose = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setIsOpen(false);
    };

    const rows_mapper = [
        {
            key: 'itemcode',
            label: 'SKU'
        },
        {
            key: 'firmname',
            label: 'Marca'
        },
        {
            key: 'nombre_proveedor',
            label: 'Nombre Proveedor'
        },
    ]

    const getTitleEquivalencesTooltip = (row: Product) => {
        return row.lictradnum === '76035324-8' ? 'Este producto ha sido remplazado. Haz click para ver sus equivalentes' : 'ServiAll cuenta con este mismo producto. Haz click para ver sus equivalentes';
    }

    if (!product) return <></>

    return (
        <>
            <Modal
                open={isOpen}
                onClose={handleOnClose}
                onClick = {(event) => event.stopPropagation() }
            >
                <Box
                    sx={{
                        backgroundColor: 'white',
                        width: '75%',
                        height: '50%',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    <Typography
                        variant="h3"
                        component="div"
                        sx={{
                            color: theme => theme.palette.primary.lightest,
                            marginBottom: '10px',
                            backgroundColor: theme => theme.palette.primary.main,
                            padding: '15px 10px'
                        }}
                    >
                        Equivalencias
                    </Typography>
                    <Box
                        sx={{
                            width: '80%',
                            margin: 'auto'
                        }}
                    >
                        {
                            product.equivalences.map((equivalence, index) =>
                                <Accordion
                                    key={index}
                                    id="accordion_no_header_padding"
                                    sx={{
                                        border: '1px solid #f0f0f0',
                                    }}
                                    onClick={(event) => event.stopPropagation()}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <Typography
                                            variant='body1'
                                            fontWeight='bold'
                                            sx={{
                                                color: theme => theme.palette.primary.dark,
                                            }}
                                        >
                                            {equivalence.itemname}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                        sx={{
                                            maxHeight: '300px',
                                            overflowY: 'auto'
                                        }}
                                    >
                                        <Stack
                                            direction='column'
                                        >
                                            <Stack direction='row' spacing={3}>
                                                {
                                                    rows_mapper.map((row, index) =>
                                                        <Stack key={index} direction="row" spacing='5px'>
                                                            <Typography
                                                                variant='body2'
                                                                fontWeight='bold'
                                                                color={theme => theme.palette.primary.dark}
                                                            >
                                                                {row.label}
                                                            </Typography>
                                                            <Typography
                                                                variant='body2'
                                                                sx={{
                                                                    ...(
                                                                        (row.key === 'nombre_proveedor' && equivalence[row.key as keyof typeof equivalence] === 'SERVIALL S.A.') && {
                                                                            color: theme => theme.palette.primary.main,
                                                                            fontWeight: 'bold',
                                                                        }
                                                                    )
                                                                }}
                                                            >
                                                                {equivalence[row.key as keyof typeof equivalence]}
                                                            </Typography>
                                                        </Stack>
                                                    )
                                                }
                                            </Stack>
                                            <Link
                                                href={`/detalle-producto?itemcode=${equivalence.itemcode}`}
                                                target='_blank'
                                                sx={{
                                                    width: 'fit-content',
                                                }}
                                            >
                                                Ver Producto
                                            </Link>
                                            {
                                                equivalence.usertext && <Box>
                                                    <div dangerouslySetInnerHTML={{ __html: equivalence.usertext }} />
                                                </Box>
                                            }
                                        </Stack>

                                    </AccordionDetails>
                                </Accordion>
                            )
                        }
                    </Box>
                </Box>
            </Modal>
            {
                product.equivalences.length > 0 && <Box>
                    <Tooltip title={getTitleEquivalencesTooltip(product)}>
                        <Button
                            sx={{ zIndex: 100 }}
                            onClick={(event) => handleOpen(event, product)}
                            startIcon={<ChangeCircleIcon />}
                        >
                            Ver {product.lictradnum === '76035324-8' ? 'Remplazos' : 'Equivalente SA'}
                        </Button>
                    </Tooltip>
                </Box>
            }
        </>
    )
}