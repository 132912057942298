import type { ChangeEvent, FC, MouseEvent } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { format } from 'date-fns';
import parseStatus from '../utils/parseStatus';
import { OpportunityStatusEnum } from '../types/opportunity';
import parseCurrency from '../utils/parseCurrency';
import { useStoreState } from '../store';
import { useNavigate } from 'react-router-dom';

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
    order: Order;
    orderBy: string;
    headCells: Array<{ id: string, label: string }>;
}
type Order = 'asc' | 'desc';

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort, headCells } =
        props;
    const createSortHandler =
        (property: any) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead
            sx={{
                position: 'sticky',
                top: '0px',
                '.MuiTableCell-root': {
                    backgroundColor: '#ef3234',
                    color: 'white',
                    '.Mui-active': {
                        backgroundColor: '#ef3234',
                        color: 'white',
                        fontWeight: 'bold',
                        '.MuiTableSortLabel-icon': {
                            color: 'white',
                        },
                    },
                },
                '.MuiTableSortLabel-root': {
                    '&:hover': {
                        color: 'white',
                        '.MuiTableSortLabel-icon': {
                            opacity: '1',
                        },
                    }
                }
            }}
        >
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{
                            backgroundColor: 'red',
                            '&:hover': {
                                cursor: 'pointer'
                            }
                        }}
                        onClick={createSortHandler(headCell.id)}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface OpportunitiesListTableProps {
    count?: number;
    items?: Array<any>;
    onPageChange?: (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
    onRowsPerPageChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onChangeSort: (item: any, endpoint: any) => void;
    page?: number;
    rowsPerPage?: number;
    headers: Array<{ id: string, label: string }>;
}

export const OpportunitiesListTable: FC<OpportunitiesListTableProps> = (props) => {
    const {
        count = 10,
        items = [],
        onPageChange = () => { },
        onRowsPerPageChange,
        onChangeSort,
        page = 0,
        rowsPerPage = 10,
        headers = []
    } = props;

    const navigate = useNavigate();

    const activeFilters = useStoreState((state: any) => state.demand.activeFilters);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: any,
    ) => {
        const isAsc = activeFilters.order.orderBy === property && activeFilters.order.order === 'asc';

        if(property !== 'valued_potencial' && property !== 'number_opportunity_sap'){
            onChangeSort({ order: isAsc ? 'desc' : 'asc', orderBy: property }, 'order')
        }

    };

    const handleOnClickRow = (row: Record<string, string>) => {
        navigate(`/detalle-oportunidad?id_oportunidad=${encodeURIComponent(row.id_oportunidad)}&nombre_cliente=${encodeURIComponent(row.nombre_cliente)}`)
    }

    return (
        <Box>
            <Table
                sx={{
                    minWidth: 750,
                    border: '1px solid #f0f0f0',
                    position: 'relative'
                }}
                aria-labelledby="tableTitle"
                size='medium'
            >
                <EnhancedTableHead
                    order={activeFilters.order.order}
                    orderBy={activeFilters.order.orderBy}
                    onRequestSort={handleRequestSort}
                    headCells={headers}
                />

                <TableBody>
                    {items.map((row, index) => {
                        return (
                            <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={`${index}-${row.id}`}
                                onClick={() => handleOnClickRow(row)}
                                sx={{
                                    cursor: 'pointer',
                                    textDecoration: 'none'
                                }}
                            >
                                <TableCell align="left">{parseStatus(row['status'] as unknown as OpportunityStatusEnum)}</TableCell>
                                <TableCell align="left">{row['portal_de_origen']}</TableCell>
                                <TableCell align="left">{row['nombre_cliente']}</TableCell>
                                <TableCell align="left">{row['id_oportunidad']}</TableCell>
                                <TableCell align="left">
                                    {row['fecha_publicacion'] ? format(new Date(row['fecha_publicacion'].toString()), 'dd/MM/yyyy HH:mm') : ''}
                                </TableCell>
                                <TableCell align="left">
                                    {row['fecha_cierre'] ? format(new Date(row['fecha_cierre'].toString()), 'dd/MM/yyyy HH:mm') : ''}
                                </TableCell>
                                <TableCell align="left">{row['cantidad_sku']}</TableCell>
                                <TableCell align="left">{parseCurrency(row['total_valued_potential']) || '-'}</TableCell>
                                <TableCell align="left">{row['number_opportunity_sap'] || '-'} </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>

            <TablePagination
                component="div"
                count={count}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
            />
        </Box >
    );
};

OpportunitiesListTable.propTypes = {
    count: PropTypes.number,
    items: PropTypes.array,
    onPageChange: PropTypes.func,
    onRowsPerPageChange: PropTypes.func,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number
};
