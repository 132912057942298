import { action, Action, Thunk, thunk } from 'easy-peasy';
import axios from '../axiosConfig';

const emptyFilters = {
    getFirms: [],
    getSup: [],
    getCatRot: [],
    getItemName: [],
    productToSearch: [],
    order: { order: 'asc', orderBy: 'prioritization_score' }
  }

interface ProductMasterModelProps {
    activeFilters: Record<string, any>;
    filterOptions: Record<any, any> | null;
    requestSearchState: null | 'loading' | 'error' | 'success';
    requestTableSearchState: null | 'loading' | 'error' | 'success';
    page: number;
    rowsPerPage: number;
    productsInPage: Record<any, any> | null;
    productToSearch: null | string;
    productsView: 'list' | 'grid';

    requestFilterOptions: Thunk<ProductMasterModelProps, string>;
    requestProductsInPage: Thunk<ProductMasterModelProps, Record<string, any> | null>;

    setProductToSearch: Action<ProductMasterModelProps, null | string>;
    setRequestSearchState: Action<ProductMasterModelProps, null | 'loading' | 'error' | 'success'>;
    setRequestTableSearchState: Action<ProductMasterModelProps, null | 'loading' | 'error' | 'success'>;
    setFilterOptions: Action<ProductMasterModelProps, Record<string, any> | null>;
    setProductsInPage: Action<ProductMasterModelProps, Record<string, any> | null>;
    setPage: Action<ProductMasterModelProps, number>;
    setRowsPerPage: Action<ProductMasterModelProps, number>;
    setActiveFilters: Action<ProductMasterModelProps, Record<string, any> | null>;
    setProductsView: Action<ProductMasterModelProps, 'list' | 'grid'>;
}

const ProductMasterModel: ProductMasterModelProps = {
    filterOptions: null,
    requestSearchState: null,
    requestTableSearchState: null,
    productsInPage: null,
    productToSearch: null,
    activeFilters: emptyFilters,
    productsView: 'list',

    page: 1,
    rowsPerPage: 10,

    setFilterOptions: action((state, payload) => {
        state.filterOptions = payload;
    }),

    setRequestSearchState: action((state, payload) => {
        state.requestSearchState = payload;
    }),

    setRequestTableSearchState: action((state, payload) => {
        state.requestTableSearchState = payload;
    }),

    setProductsInPage: action((state, payload) => {
        state.productsInPage = payload;
    }),

    setPage: action((state, payload) => {
        state.page = payload;
    }),

    setRowsPerPage: action((state, payload) => {
        state.rowsPerPage = payload;
    }),

    setProductToSearch: action((state, payload) => {
        state.productToSearch = payload;
    }),

    setProductsView: action((state, payload) => {
        state.productsView = payload;
    }),

    setActiveFilters: action((state, payload: Record<string, any>) => {

        if(!payload){
            state.activeFilters = emptyFilters
            return
        }

        const { item, endpoint, checked } = payload

        if (endpoint === 'productToSearch' || endpoint === 'getItemName') {
            if (checked) {

                if (endpoint === 'getItemName'){
                    state.productToSearch = null
                    state.activeFilters = { ...state.activeFilters, productToSearch: [] }
                } else {
                    state.activeFilters = { ...state.activeFilters, getItemName: [] }
                }

                state.activeFilters = { ...state.activeFilters, [endpoint]: [item] }
            } else {
              state.activeFilters = { ...state.activeFilters, [endpoint]: [] }
              state.productToSearch = null
            }
          }
          else if (endpoint === 'order') {
            state.activeFilters = { ...state.activeFilters, [endpoint]: { order: item.order, orderBy: item.orderBy } }
          }
          else {
            if (checked) {
              state.activeFilters = { ...state.activeFilters, [endpoint]: [...state.activeFilters[endpoint], item] }
            } else {
              state.activeFilters = { ...state.activeFilters, [endpoint]: state.activeFilters[endpoint].filter((current_item: Record<string, any>) => (current_item.id !== item.id)), }
            }
          }
    }),

    requestProductsInPage: thunk(async (actions, payload: any, { getState }) => {
        try {
            actions.setRequestTableSearchState('loading')
            const { page, rowsPerPage, productsInPage, productToSearch } = getState()
            
            actions.setProductsInPage(null)
            
            let getSup = payload?.filters?.getSup || null
            let {order, orderBy} = payload?.filters?.order || {order: 'desc', orderBy: 'prioritization_score'}
            const getFirms = payload?.filters?.getFirms || null
            const getCatRot = payload?.filters?.getCatRot || null
            const getItemName = payload?.filters?.getItemName || null
            const description = payload?.description || productToSearch || null

            console.log(!getFirms && !getSup && !getCatRot && !getItemName && !description)
            console.log(getFirms, getSup, getCatRot, getItemName, description)

            if(!getFirms?.length && !getSup?.length && !getCatRot?.length && !getItemName?.length && !description){
                console.log('No filters')
                getSup = ['SERVIALL S.A.']
                order = 'asc'
                orderBy ='itemname'
            }


            let response = null
            
            if (productToSearch) {
                
                if (payload?.description) {
                    actions.setPage(1)

                    response = await axios.post('/search/search',
                        {
                            description,
                            lictradnum: getSup?.length > 0 ? getSup : null,
                            firmname: getFirms?.length > 0 ? getFirms : null,
                            categoria_rotacion: getCatRot?.length > 0 ? getCatRot : null,
                        },
                        {
                            headers: { 'Content-Type': 'application/json' },
                            signal: payload.signal
                        }
                    );

                    response = {
                        ...response,
                        data: {
                            ...response.data,
                            data: {
                                allData: response.data['data'],
                                TotalPages: Math.ceil(response.data['data'].length / rowsPerPage),
                                TotalRecords: response.data['data'].length,
                                Datos: response.data['data'].slice((page-1) * rowsPerPage, (page-1) * rowsPerPage + rowsPerPage),
                                Page: 1,
                                isIASearch: true,
                            },
                        }
                    }
                } else {
                    response = {
                        status: 200,
                        data: {
                            error: false,
                            data: {
                                ...productsInPage,
                                Datos: productsInPage!.allData.slice((page-1) * rowsPerPage, (page-1) * rowsPerPage + rowsPerPage),
                                Page: page,
                                isIASearch: true,
                            }
                        }
                    }
                }


            } else {
                response = await axios.post(`/product-master/articulos`,
                    {
                        firms: getFirms?.length > 0 ? getFirms : null,
                        lics: getSup?.length > 0 ? getSup : null,
                        cats: getCatRot?.length > 0 ? getCatRot : null,
                        itemname: getItemName?.length > 0 ? getItemName[0] : null,
                        page,
                        rowsPerPage,
                        order: {order, orderBy}
                    },
                    {
                        headers: { 'Content-Type': 'application/json' },
                        timeout: 120000,
                        signal: payload.signal
                    },
                );
            }
            
            actions.setProductsInPage(response.data['data']);
            actions.setRequestSearchState( (response.status === 200 && response.data.error === false) ? 'success' : 'error')
            actions.setRequestTableSearchState( (response.status === 200 && response.data.error === false) ? 'success' : 'error')

            return true;
        } catch (error: any) {
            if (error?.name === 'CanceledError') {
                return false;
            }
            actions.setRequestSearchState('error')
            actions.setRequestTableSearchState('error')
            console.error('Error fetching items in page:', error);
            return false;
        }
    }),
    requestFilterOptions: thunk(async (actions) => {
        try {
            const response = await axios.get(`/product-master/filter-options`);
            actions.setFilterOptions(response.data['data']);
            return true;
        } catch (error) {
            console.error('Error fetching users:', error);
            return false;
        }
    }),

};

export default ProductMasterModel;