import { useState, useEffect, memo } from "react"
import { useStoreState } from '../store';
import { Fab, TextField } from "@mui/material"
import { Stack } from "@mui/system"
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const DemandIdOpportunityFilter = memo(({ onSubmitIdOpportunity }: { onSubmitIdOpportunity: (item: any, endpoint: string) => void }) => {
    const activeFilters = useStoreState((state: any) => state.demand.activeFilters);
    const [idOpportunity, setIdOpportunity] = useState("");

    useEffect(() => {
        if (!activeFilters?.id_oportunidad) {
            setIdOpportunity("");
        }
    }, [activeFilters?.id_oportunidad]);    

    return (
        <Stack
            direction='row'
            alignItems='center'
            component='form'
            onSubmit={(event: any) => { event.preventDefault(); onSubmitIdOpportunity(event.currentTarget.elements.idOpportunity.value, 'id_oportunidad') }}
        >
            <TextField
                id="idOpportunity"
                variant="standard"
                fullWidth
                value={idOpportunity}
                onChange={(e) => setIdOpportunity(e.target.value)}
            />
            <Fab
                color="primary"
                aria-label="edit"
                size="small"
                type="submit"
                sx={{ width: "24px", height: "24px", minHeight: "24px" }}
            >
                <KeyboardArrowRightIcon />
            </Fab>
        </Stack>
    );
});

export default DemandIdOpportunityFilter;