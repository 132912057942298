import { FC, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import DatePicker from './date-picker';
import DownloadIcon from '@mui/icons-material/Download';
import { Typography, Button, Backdrop, CircularProgress, Snackbar, Alert, SnackbarCloseReason, Tooltip } from '@mui/material';
import { useStoreState, useStoreActions } from '../store';
import { SaveOpportunityPayloadType, OpportunityStatusEnum, OpportunityItemsType } from '../types/opportunity';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import { Box, Stack } from '@mui/system';
import { format } from 'date-fns';
import ModalEmailOpportunity from './modal-email-opportunity';
import SaveIcon from '@mui/icons-material/Save';
import WarningIcon from '@mui/icons-material/Warning';

interface detailOpportunityHeaderListProps {
    readonly headerItems: Record<string, any>
    readonly idOpportunity: string,
    readonly nombreCliente: string,
    readonly opportunityDetail: Record<string, any>
}

const DetailOpportunityHeaderList: FC<detailOpportunityHeaderListProps> = ({ headerItems, idOpportunity, nombreCliente, opportunityDetail }) => {
    const [codeSnClient, setcodeSnClient] = useState(
        headerItems.id_opportunities_table ? (
            headerItems['code_sn_client'] ? headerItems['code_sn_client'] : ''
        ) :
            (
                headerItems['cardcode'] ? headerItems['cardcode'] : ''
            )
    )
    const [typeSell, settypeSell] = useState(headerItems['type_sell'] ? headerItems['type_sell'] : 'D1-10017')
    const [unitBusiness, setunitBusiness] = useState(headerItems['unit_business'] ? headerItems['unit_business'] : 'D2-10011')
    const [costCenter, setcostCenter] = useState(headerItems['cost_center'] ? headerItems['cost_center'] : 'D3-10049')
    const [dateDelivery, setdateDelivery] = useState(headerItems['date_delivery'] ? headerItems['date_delivery'] : null)
    const [placeDelivery, setplaceDelivery] = useState(headerItems['place_delivery'] ? headerItems['place_delivery'] : '')
    const [comments, setcomments] = useState(headerItems['comments'] ? headerItems['comments'] : '')
    const [emptyFieldError, setEmptyFieldError] = useState<boolean>(false)

    const requestSaveOpportunityState = useStoreState((state: any) => state.opportunityDetail.requestSaveOpportunityState);

    const requestSaveOpportunity = useStoreActions((actions: any) => actions.opportunityDetail.requestSaveOpportunity);
    const setRequestSaveOpportunityState = useStoreActions((actions: any) => actions.opportunityDetail.setRequestSaveOpportunityState);
    const requestCreateSAP = useStoreActions((actions: any) => actions.opportunityDetail.requestCreateSAP);
    const requestDownloadOfferPDF = useStoreActions((actions: any) => actions.opportunityDetail.requestDownloadOfferPDF);

    const handleSaveOpportunityChanges = (status: SaveOpportunityPayloadType["status"]) => {

        const is_error = !codeSnClient || !dateDelivery
        setEmptyFieldError(is_error)
        if (is_error) {
            return
        }

        const items_opportunity: Array<OpportunityItemsType> = opportunityDetail.map((item: any) => ({
            id: item.id,
            id_product: item.itemcode_match_sugerido,
            index: item.index,
            offered: item.offered,
            cluster_match_requested: item.cluster_match_sugerido !== null ? item.cluster_match_sugerido.toString() : null,
            similarity_cosine_match_suggested: item.similitud_coseno_match_sugerido,
            score_prioritization_match_suggested: item.score_priorizacion_match_sugerido,
            name_provider_match: item.nombre_proveedor_match,
            amount_requested: item.cantidad_solicitada,
            itemname_requested: item.itemname_solicitado,
            itemname_match_requested: item.itemname_match_sugerido,
            itemcode_requested: item.itemcode_solicitado,
            estimated_cost: item.estimated_cost_match_sugerido,

            stock: item.available_clds && parseFloat(item.available_clds),
            rotation_category: item.categoria_rotacion,
            // selling_price -> Is taken from state
        }))

        // const comments_add =  placeDelivery ? `${comments} - Lugar de entrega: ${placeDelivery}` : comments

        const data: SaveOpportunityPayloadType = {
            id: headerItems.id,
            id_opportunities_table: headerItems.id_opportunities_table,
            id_opportunity: idOpportunity,
            name_client: nombreCliente,
            number_opportunity_sap: headerItems.number_opportunity_sap,
            origin_portal: headerItems.portal_de_origen,
            code_sn_client: codeSnClient,
            type_sell: typeSell,
            unit_business: unitBusiness,
            cost_center: costCenter,
            date_delivery: dateDelivery ? dateDelivery : null,
            place_delivery: placeDelivery,
            comments: comments,
            status: status,
            items: items_opportunity
        }
        setRequestSaveOpportunityState({ state: 'loading' })

        if (status === OpportunityStatusEnum.OFFERTED) {
            requestCreateSAP(data)
        } else {
            requestSaveOpportunity(data)
        }
    }

    const handleOnCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return;
        }

        setRequestSaveOpportunityState({ state: null });
    }

    return (
        <Stack
            direction='row'
            justifyContent="space-around"
            alignItems="center"
        >

            <>
                <Backdrop
                    sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 2 })}
                    open={requestSaveOpportunityState['state'] === 'loading'}
                >
                    <CircularProgress color="primary" />
                </Backdrop>
                {
                    (requestSaveOpportunityState['state'] === 'success' || requestSaveOpportunityState['state'] === 'error') &&
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        open={true}
                        autoHideDuration={3000}
                        onClose={handleOnCloseSnackbar}
                    >
                        <Alert
                            severity={requestSaveOpportunityState['state']}
                            variant="filled"
                            sx={{ width: '100%', color: 'white' }}
                            onClose={handleOnCloseSnackbar}
                        >
                            {
                                requestSaveOpportunityState['state'] === 'success' ? "Oportunidad Guardada con Éxito" : requestSaveOpportunityState['message']
                            }
                        </Alert>
                    </Snackbar>
                }
            </>

            <List disablePadding>
                <ListItem sx={{ px: 3, py: 1.5 }}>
                    <ListItemText disableTypography
                        sx={{ display: 'flex', flexDirection: 'row', my: 0 }}
                        primary={(
                            <Typography sx={{ minWidth: 180 }} color="text.secondary" variant="body2">
                                Nombre Cliente
                            </Typography>
                        )}
                        secondary={(
                            <Box sx={{ flex: 1, mt: 0 }}>
                                <Typography variant="subtitle2">
                                    {headerItems['nombre_cliente']}
                                </Typography>
                            </Box>
                        )}
                    />
                </ListItem>
                <ListItem sx={{ px: 3, py: 1.5 }}>
                    <ListItemText disableTypography
                        sx={{ display: 'flex', flexDirection: 'row', my: 0 }}
                        primary={(
                            <Typography sx={{ minWidth: 180 }} color="text.secondary" variant="body2">
                                Portal de Origen
                            </Typography>
                        )}
                        secondary={(
                            <Box sx={{ flex: 1, mt: 0 }}>
                                <Typography variant="subtitle2">
                                    {headerItems['portal_de_origen']}
                                </Typography>
                            </Box>
                        )}
                    />
                </ListItem>
                <ListItem sx={{ px: 3, py: 1.5 }}>
                    <ListItemText disableTypography
                        sx={{ display: 'flex', flexDirection: 'row', my: 0 }}
                        primary={(
                            <Typography sx={{ minWidth: 180 }} color="text.secondary" variant="body2">
                                Fecha de Publicación
                            </Typography>
                        )}
                        secondary={(
                            <Box sx={{ flex: 1, mt: 0 }}>
                                <Typography variant="subtitle2">
                                    {headerItems['fecha_publicacion'] ? format(new Date(headerItems['fecha_publicacion']), 'dd/MM/yyyy HH:mm') : ''}
                                </Typography>
                            </Box>
                        )}
                    />
                </ListItem>
                <ListItem sx={{ px: 3, py: 1.5 }}>
                    <ListItemText disableTypography
                        sx={{ display: 'flex', flexDirection: 'row', my: 0 }}
                        primary={(
                            <Typography sx={{ minWidth: 180 }} color="text.secondary" variant="body2">
                                ID Oportunidad
                            </Typography>
                        )}
                        secondary={(
                            <Box sx={{ flex: 1, mt: 0 }}>
                                <Typography variant="subtitle2">
                                    {headerItems['id_oportunidad']}
                                </Typography>
                            </Box>
                        )}
                    />
                </ListItem>
                <ListItem sx={{ px: 3, py: 1.5 }}>
                    <ListItemText disableTypography
                        sx={{ display: 'flex', flexDirection: 'row', my: 0 }}
                        primary={(
                            <Typography sx={{ minWidth: 180 }} color="text.secondary" variant="body2">
                                Cantidad SKU
                            </Typography>
                        )}
                        secondary={(

                            opportunityDetail?.length < headerItems['cantidad_sku']
                                ?
                                <Tooltip
                                    title='Puedes ver todas las líneas desde el portal'
                                >
                                    <Box sx={{ flex: 1, mt: 0, display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '5px' }}>
                                        <>
                                            <Typography variant="subtitle2">
                                                {headerItems['cantidad_sku']}
                                            </Typography>

                                            <WarningIcon color='warning' sx={{ fontSize: '1rem' }} />
                                        </>

                                    </Box>
                                </Tooltip>
                                :
                                <Box sx={{ flex: 1, mt: 0 }}>
                                    <Typography variant="subtitle2">
                                        {headerItems['cantidad_sku']}
                                    </Typography>
                                </Box>

                        )}
                    />
                </ListItem>
                <ListItem sx={{ px: 3, py: 1.5 }}>
                    <ListItemText disableTypography
                        sx={{ display: 'flex', flexDirection: 'row', my: 0 }}
                        primary={(
                            <Typography sx={{ minWidth: 180 }} color="text.secondary" variant="body2">
                                Direccion de entrega
                            </Typography>
                        )}
                        secondary={(
                            <Box sx={{ flex: 1, mt: 0 }}>
                                <Typography variant="subtitle2">
                                    {headerItems['lugar_entrega'] === '' ? headerItems['lugar_entrega'] : 'N/A'}
                                </Typography>
                            </Box>
                        )}
                    />

                </ListItem>
                <ListItem sx={{ px: 3, py: 1.5 }}>
                    <ListItemText disableTypography
                        sx={{ display: 'flex', flexDirection: 'row', my: 0 }}
                        primary={(
                            <Typography sx={{ minWidth: 180 }} color="text.secondary" variant="body2">
                                Fecha de Cierre
                            </Typography>
                        )}
                        secondary={(
                            <Box sx={{ flex: 1, mt: 0 }}>
                                <Typography variant="subtitle2">
                                    {headerItems['fecha_cierre'] ? format(new Date(headerItems['fecha_cierre']), 'dd/MM/yyyy HH:mm') : ''}
                                </Typography>
                            </Box>
                        )}
                    />

                </ListItem>
                <ListItem sx={{ px: 3, py: 0.5, mt: 2 }}>
                    <ModalEmailOpportunity htmlEmail={headerItems['htmlbody']} />
                </ListItem>
                <ListItem sx={{ px: 3, py: 0.5, ml: '18%' }}>
                    <Button
                        variant='contained'
                        sx={
                            {
                                'backgroundColor': '#343842',
                                '&:hover':
                                {
                                    'backgroundColor': '#e1e1e1',
                                    'color': 'black'
                                }
                            }
                        }
                        startIcon={<DeleteIcon />}
                        onClick={() => handleSaveOpportunityChanges(OpportunityStatusEnum.DECLINED)}
                        disabled={!(headerItems.status === OpportunityStatusEnum.OPEN || headerItems.status === OpportunityStatusEnum.IN_PROCESS)}
                    >
                        Declinar oferta
                    </Button>
                </ListItem>
            </List>
            <List disablePadding>
                <ListItem sx={{ px: 3, py: 0.5 }}>
                    <ListItemText disableTypography
                        sx={{ display: 'flex', flexDirection: 'row', my: 0 }}
                        primary={(
                            <Typography sx={{ minWidth: 180 }} color="text.secondary" variant="body2">
                                Codigo SN Cliente
                            </Typography>
                        )}
                        secondary={(
                            <TextField
                                error={emptyFieldError && !codeSnClient}
                                helperText="Campo Obligatorio"
                                defaultValue={codeSnClient}
                                hiddenLabel
                                name="codeSnClient"
                                size="small"
                                onChange={(e) => setcodeSnClient(e.target.value)}
                            />
                        )}
                    />
                </ListItem>
                <ListItem sx={{ px: 3, py: 0.5 }}>
                    <ListItemText disableTypography
                        sx={{ display: 'flex', flexDirection: 'row', my: 0 }}
                        primary={(
                            <Typography sx={{ minWidth: 180 }} color="text.secondary" variant="body2">
                                Tipo de venta
                            </Typography>
                        )}
                        secondary={(
                            <TextField
                                defaultValue={typeSell}
                                hiddenLabel
                                name="typeSell"
                                size="small"
                                disabled
                                onChange={(e) => settypeSell(e.target.value)}
                            />
                        )}
                    />
                </ListItem>
                <ListItem sx={{ px: 3, py: 0.5 }}>
                    <ListItemText disableTypography
                        sx={{ display: 'flex', flexDirection: 'row', my: 0 }}
                        primary={(
                            <Typography sx={{ minWidth: 180 }} color="text.secondary" variant="body2">
                                Unidad de negocio
                            </Typography>
                        )}
                        secondary={(
                            <TextField
                                defaultValue={unitBusiness}
                                hiddenLabel
                                name="unitBusiness"
                                size="small"
                                disabled
                                onChange={(e) => setunitBusiness(e.target.value)}
                            />
                        )}
                    />
                </ListItem>
                <ListItem sx={{ px: 3, py: 0.5 }}>
                    <ListItemText disableTypography
                        sx={{ display: 'flex', flexDirection: 'row', my: 0 }}
                        primary={(
                            <Typography sx={{ minWidth: 180 }} color="text.secondary" variant="body2">
                                Centro de costos
                            </Typography>
                        )}
                        secondary={(
                            <TextField
                                defaultValue={costCenter}
                                hiddenLabel
                                name="costCenter"
                                size="small"
                                disabled
                                onChange={(e) => setcostCenter(e.target.value)}
                            />
                        )}
                    />
                </ListItem>
                <ListItem sx={{ px: 3, py: 0.5 }}>
                    <ListItemText disableTypography
                        sx={{ display: 'flex', flexDirection: 'row', my: 0 }}
                        primary={(
                            <Typography sx={{ minWidth: 180 }} color="text.secondary" variant="body2">
                                Plazo de entrega
                            </Typography>
                        )}
                        secondary={(
                            <DatePicker
                                error={emptyFieldError && !dateDelivery}
                                label="Fecha de Plazo Máximo"
                                date={dateDelivery}
                                setDate={setdateDelivery}
                                inputProps={{
                                    min: format(new Date(), 'yyyy-MM-dd')
                                }}
                            />
                        )}
                    />
                </ListItem>
                <ListItem sx={{ px: 3, py: 0.5 }}>
                    <ListItemText disableTypography
                        sx={{ display: 'flex', flexDirection: 'row', my: 0 }}
                        primary={(
                            <Typography sx={{ minWidth: 180 }} color="text.secondary" variant="body2">
                                Lugar de entrega
                            </Typography>
                        )}
                        secondary={(

                            <TextField
                                defaultValue={placeDelivery}
                                hiddenLabel
                                name="placeDelivery"
                                size="small"
                                onChange={(e) => setplaceDelivery(e.target.value)}
                            />
                        )}
                    />
                </ListItem>
                <ListItem sx={{ px: 3, py: 0.5 }}>
                    <ListItemText disableTypography
                        sx={{ display: 'flex', flexDirection: 'row', my: 0 }}
                        primary={(
                            <Typography sx={{ minWidth: 180 }} color="text.secondary" variant="body2">
                                Comentarios adicionales
                            </Typography>
                        )}
                        secondary={(
                            <TextField
                                defaultValue={comments}
                                hiddenLabel
                                name="comments"
                                size="small"
                                onChange={(e) => setcomments(e.target.value)}
                            />
                        )}
                    />
                </ListItem>
                <ListItem sx={{ px: 3, py: 0.5 }}>
                    <Button
                        variant='contained'
                        sx={{
                            margin: 'auto',
                            '.MuiButton-startIcon>*:nth-of-type(1)':
                            {
                                fontSize: '12px',
                                fontWeight: 'bolder'
                            }
                        }}
                        startIcon={<Typography variant='caption'>SAP</Typography>}
                        onClick={() => handleSaveOpportunityChanges(OpportunityStatusEnum.OFFERTED)}
                        disabled={!headerItems.id_opportunities_table || !(headerItems.status === OpportunityStatusEnum.OPEN || headerItems.status === OpportunityStatusEnum.IN_PROCESS)}
                    >
                        Enviar/Actualizar oferta a SAP
                    </Button>

                    <Button
                        variant='contained'
                        sx={{
                            marginRight: 'auto',
                            'backgroundColor': '#737373',
                            '&:hover': {
                                'backgroundColor': '#e1e1e1',
                                'color': 'black'
                            }
                        }}
                        startIcon={<DownloadIcon />}
                        disabled={!headerItems.doc_entry}
                        onClick={() => requestDownloadOfferPDF(headerItems.doc_entry)}
                    >
                        Descargar oferta
                    </Button>
                </ListItem>
                <ListItem sx={{ px: 20 }}>
                    <Button
                        variant='contained'
                        sx={
                            {
                                marginRight: 0,
                                'backgroundColor': '#343842',
                                '&:hover':
                                {
                                    'backgroundColor': '#e1e1e1',
                                    'color': 'black'
                                }
                            }
                        }
                        startIcon={<SaveIcon />}
                        onClick={() => handleSaveOpportunityChanges(OpportunityStatusEnum.IN_PROCESS)}
                        disabled={!(headerItems.status === OpportunityStatusEnum.OPEN || headerItems.status === OpportunityStatusEnum.IN_PROCESS)}
                    >
                        Guardar cambios
                    </Button>
                </ListItem>

            </List>

        </Stack>
    )
}

export default DetailOpportunityHeaderList;