import type { ChangeEvent, FC, MouseEvent } from 'react';
import PropTypes from 'prop-types';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import type { Product } from '../types/product';

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import { useNavigate } from 'react-router-dom';
import ProductImage from './product-image';

import Tooltip from '@mui/material/Tooltip';
import { RotationChip } from '../utils/rotation_category';
import { Box } from '@mui/system';
import serviall_logo from '../assets/serviall_logo.png';
import ParametersChip from './parameters-chip';
import { Alert } from '@mui/material';
import { ModalProductEquivalences } from './modal-product-equivalences';

interface ProductListGridProps {
    count?: number;
    items?: Product[];
    onPageChange?: (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
    onRowsPerPageChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    page?: number;
    rowsPerPage?: number;
}

interface ProductCardProps {
    readonly product: Product
}

interface CardDescriptionProps {
    readonly title: string
    readonly subtitle: string
}

const CardDescription: FC<CardDescriptionProps> = ({ title, subtitle }) => {
    return <Stack direction='row' spacing={1}>
        <Typography variant="body2">
            {title}:
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {subtitle}
        </Typography>
    </Stack>
}

const getChipColor = (stock: number | null) => {
    if (stock === null) {
        return '#06AED4'
    }
    if (stock === 0) {
        return '#f04438'
    }
    if (stock >= 0) {
        return '#10b981'
    }
    return 'error'
}

const getStockText = (stock: number | null) => {
    if (stock === null) {
        return 'Sin Info'
    }
    if (stock === 0) {
        return 'Sin Stock'
    }
    if (stock >= 0) {
        return `Stock: ${stock}`
    }
    return 'error'
}

const ProductCard: FC<ProductCardProps> = ({ product }) => {
    const navigate = useNavigate();

    const handleCardOnClick = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
        navigate(`/detalle-producto?itemcode=${product.itemcode}`)
    }

    return (
        <Card
            sx={{
                width: '100%',
                backgroundColor: 'white',
                border: '1px solid #f0f0f0',
                'WebkitBoxShadow': '0px 0px 33px -10px rgba(209,209,209,1);',
                'MozBoxShadow': '0px 0px 33px -10px rgba(209,209,209,1);',
                'boxShadow': '0px 0px 33px -10px rgba(209,209,209,1);',
                ...(
                    product.item_descontinuado !== 'No Desc.' && {
                        color: 'gray',
                        fontStyle: 'italic',
                    }
                )
            }}
            variant='outlined'
            key={product.itemcode}
        >
            
            <CardActionArea
                sx={{
                    textDecoration: 'none',
                    position: 'relative',
                }}
                onClick={(event) => handleCardOnClick(event) }
                component='div'
                disableRipple
            >
                <CardMedia
                    sx={{
                        position: 'relative',
                    }}
                    >   
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative',
                            ...(
                                product.item_descontinuado !== 'No Desc.' && {
                                    opacity: 0.8,
                                    filter: 'blur(2px)',
                                }
                            )
                        }}
                    >
                        <ProductImage
                            itemcode={product.itemcode}
                            width={'50%'}
                            height={'50%'}
                            link_imagen={product.link_imagen}
                        />
                    </Box>
                    <Box sx={{ position: 'absolute', top: '10px', right: '10px' }}>
                        <ParametersChip product={product} direction='column' />
                    </Box>
                    
                    {
                        product.item_descontinuado !== 'No Desc.' &&
                            <Alert 
                                variant='filled'
                                severity='error'
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: 0,
                                    width: '100%',
                                    borderRadius: '10px',
                                    '.MuiAlert-message': {
                                        color: 'white',
                                        fontSize: '1.5em',
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        margin: 'auto',
                                        fontStyle: 'normal',
                                    },
                                    '.MuiAlert-icon': {
                                        fontSize: '30px',
                                        margin: 'auto',
                                        padding: '0px'
                                    }
                                }}
                            >
                                Producto Descontinuado
                            </Alert>
                    }

                    <Typography 
                        variant="caption"
                        component="div"
                        sx={{
                            color: 'white',
                            position: 'absolute',
                            bottom: 0,
                            right: '10px',
                            padding: '0px 6px',
                            backgroundColor: getChipColor(product.available_clds),
                            borderRadius: '10px',
                            zIndex: 1
                        }}
                    >
                        {getStockText(product.available_clds)}
                    </Typography>
                </CardMedia>

                <CardContent
                    sx={{
                        padding: '16px',
                    }}
                >
                    <Stack spacing={0}>

                        <Typography gutterBottom variant="h6" component="div" 
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '2',
                                height: '2.5em',
                                WebkitBoxOrient: 'vertical',
                            }}
                        >
                            {product.itemname}
                        </Typography>

                        <ModalProductEquivalences product={product} />

                        <CardDescription title='SKU' subtitle={product.itemcode} />

                        <Stack direction='row' spacing={1}>
                            <Typography variant="body2" display='flex' alignItems='center'>
                                {'Proveedor: '}
                                {
                                    product.lictradnum === '76035324-8' ?
                                        <Box
                                            component='img'
                                            src={serviall_logo}
                                            width='60px'
                                            marginLeft='5px'
                                            borderRadius='5px'
                                        />
                                        : product.nombre_proveedor
                                }
                            </Typography>
                            
                        
                        </Stack>

                        <Tooltip title="Indica la frecuencia de venta del producto (en meses). Primera letra considera últimos 3 meses, segunda letra considera último año.">
                            <Stack direction='row' spacing={1} alignItems='center' >
                                <Typography variant="body2">
                                    Rotación:
                                </Typography>
                                <RotationChip 
                                    rotation_category={product.categoria_rotacion}
                                    size='small'
                                />
                            </Stack>
                        </Tooltip>

                        <CardDescription title='Marca' subtitle={product.firmname} />
                    </Stack>
                </CardContent>

            </CardActionArea>
        </Card>
    )
}

export const ProductListGrid: FC<ProductListGridProps> = (props) => {
    const {
        count = 0,
        items = [],
        onPageChange = () => { },
        onRowsPerPageChange,
        page = 0,
        rowsPerPage = 0,
    } = props;

    return (
        <Stack
            sx={{
                width: '100%',
                position: 'relative'
            }}
        >
            <Grid
                container
                spacing={1}
                wrap='wrap'
                sx={{
                    width: '100%',
                    justifyContent: "space-evenly",
                }}
            >
                {
                    items.map((product, idx) =>
                        <Grid item key={idx} xs={12} sm={6} md={3} xl={2.4}>
                            <ProductCard {...{ product }} />
                        </Grid>
                    )
                }
            </Grid>
            <TablePagination
                component="div"
                count={count}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
            />
        </Stack>
    );
};

ProductListGrid.propTypes = {
    count: PropTypes.number,
    items: PropTypes.array,
    onPageChange: PropTypes.func,
    onRowsPerPageChange: PropTypes.func,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number
};
