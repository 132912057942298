import { FC, useState, useEffect, useMemo, MouseEvent, ChangeEvent } from 'react';
import { useLocation } from "react-router-dom";
import { useStoreState, useStoreActions } from '../../store';
import { Box, Stack } from '@mui/system';
import { Card, CardContent, Typography, Button, Alert } from '@mui/material';

import logo from '../../assets/out-of-stock.png';

import { OpportunityItemsListTable } from '../../components/list-table-opportunity-items';
import { CircularLoader } from '../../components/circular-loader';
import parseStatus from '../../utils/parseStatus';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import StateRequestScreen from '../../components/state-request-screen';
import DetailOpportunityHeaderList from '../../components/detail-opportunity-header-list';

function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

const OpportunityDetailPage: FC = ({ }) => {
    const navigate = useNavigate();
    let query = useQuery();
    const idOpportunity = decodeURIComponent(query.get('id_oportunidad') || '')
    const nombreCliente = decodeURIComponent(query.get('nombre_cliente') || '')

    const opportunityDetail = useStoreState((state: any) => state.opportunityDetail.opportunityDetail);
    const opportunityHeader = useStoreState((state: any) => state.opportunityDetail.opportunityHeader);
    const requestOpportunityDetail = useStoreActions((actions: any) => actions.opportunityDetail.requestOpportunityDetail);
    const requestFilterOptions = useStoreActions((actions: any) => actions.opportunityDetail.requestFilterOptions);
    const setOpportunityDetail = useStoreActions((actions: any) => actions.opportunityDetail.setOpportunityDetail);
    const requestSearchState = useStoreState((state: any) => state.opportunityDetail.requestSearchState);

    const [page, setPage] = useState<number>(1)
    const [rowsPerPage, setRowsPerPage] = useState<number>(5)

    useEffect(() => {
        requestOpportunityDetail({ idOpportunity, nombreCliente })

        return () => {
            setOpportunityDetail(null)
        }
    }, [idOpportunity, nombreCliente, requestOpportunityDetail])

    useEffect(() => {
        requestFilterOptions()
    }, [])

    const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
        setPage(newPage + 1)
    }

    const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setRowsPerPage(parseInt(event.target.value))
    }
    
    if(requestSearchState === 'error') return (
        <Box
            height='100%'
            width='50%'
            sx={{
                display: 'flex',
                justifyItems: 'center',
                alignItems: 'center',
                margin: 'auto'
            }}
        >
            <StateRequestScreen text='No es posible mostrar la oportunidad en este momento' status='error' />
        </Box>
    )

    if (!opportunityDetail) return (
        <Box
            height='100%'
            width='100%'
        >
            <CircularLoader state={requestSearchState} />
        </Box>
    )

    if (!opportunityDetail && requestSearchState !== 'loading') return (
        <Box
          sx={{
            display: 'flex',
            height: '100%'
          }}
        >
          <Card
            sx={{
              backgroundColor: 'white',
              width: '50%',
              margin: ' auto',
              height: '50%',
              borderRadius: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}
          >
            <Box
              component="img"
              src={logo}
              alt="buscar"
              sx={
                {
                  width: '50%',
                }
              }
            />
            <Typography variant='subtitle1'>
              Oportunidad con ID {idOpportunity} del cliente {nombreCliente} No encontrada
            </Typography>
          </Card>
        </Box>
      )

    return (
        <>
            <Stack
                sx={{
                    height: '100%',
                    width: '90%',
                    backgroundColor: 'white',
                    margin: '2em auto',
                    padding: '1em',
                    position: 'relative'
                }}
            >
                <Card
                    sx={{
                        width: '95%',
                        margin: '20px auto',
                        borderTop: '10px solid #e84145',
                    }}
                >
                    <CardContent>
                        <Stack
                            direction='row'
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            <Stack
                                direction='row'
                                justifyContent = "flex-start"
                                alignItems = "center"
                                spacing={2}
                                sx={{
                                    borderBottom: '2px solid red',
                                    paddingBottom: '4px',
                                    height: '50px',
                                    lineHeight: '50px'
                                }}
                            >
                                <Button
                                    variant='contained'
                                    sx={{ 
                                        padding: '3px',
                                        minWidth: '50px',
                                        backgroundColor: '#343842',
                                        '&:hover': {
                                            backgroundColor: '#e1e1e1',
                                            color: 'black'
                                        } 
                                    }} 
                                    onClick={() => navigate(-1)}
                                >
                                    <ArrowBackIcon />
                                </Button>
                                <Typography
                                    variant='h3'
                                    marginBottom='15px'
                                >
                                    Detalle de Oportunidad
                                    {
                                        opportunityHeader.id_opportunities_table &&
                                        <Typography variant='subtitle1' display='inline' component='span' color='#737373'>
                                            (#{opportunityHeader.id_opportunities_table})
                                        </Typography>
                                    }
                                </Typography>
                            </Stack>
                            <Box>
                                <Typography
                                    variant='body1'
                                    bgcolor='#e5e940'
                                    padding='2px 5px'
                                    borderRadius='5px'
                                >
                                    Estado Oportunidad: <Typography variant='body1' fontWeight='bold' display='inline' component="span">{parseStatus(opportunityHeader.status)}</Typography>
                                </Typography>

                                <Typography variant='body1'>N° Cotización SAP: {opportunityHeader.number_opportunity_sap ? opportunityHeader.number_opportunity_sap : '-' }</Typography>
                            </Box>
                        </Stack>
                        <DetailOpportunityHeaderList
                            headerItems={opportunityHeader}
                            idOpportunity={idOpportunity}
                            nombreCliente={nombreCliente}
                            opportunityDetail={opportunityDetail}
                        />
                    </CardContent>
                </Card>
                <Card
                    sx={{
                        width: '95%',
                        margin: '5px auto'
                    }}
                >
                    <CardContent
                        sx={{
                            overflow: 'auto'
                        }}
                    >
                        {
                            opportunityDetail?.length < opportunityHeader['cantidad_sku'] &&
                                <Alert
                                    severity='warning'
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                >
                                    Se muestra un resumen de la oportunidad. Para mayor información ingresar al portal {opportunityHeader.portal_de_origen}
                                </Alert>
                        }
                        <OpportunityItemsListTable
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            headers={[
                                { id: 'ofertar', label: 'Ofertar' },
                                { id: 'editar', label: 'Editar' },
                                { id: 'itemcode_solicitado', label: 'Sku Cliente' },
                                { id: 'itemname_solicitado', label: 'Descripción' },
                                { id: 'itemname_match_sugerido', label: 'Match Sugerido' },
                                { id: 'itemcode_match_sugerido', label: 'SKU Match Sugerido' },
                                { id: 'nombre_proveedor_match', label: 'Proveedor Match' },
                                { id: 'rotation_category', label: 'Rotación' },
                                { id: 'cantidad_solicitada', label: 'Cantidad' },
                                { id: 'available_clds', label: 'Stock disponible' },
                                { id: 'cluster_match_sugerido', label: 'Calidad Match' },
                                { id: 'similitud_coseno_match_sugerido', label: '% Confiabilidad Match' },
                                { id: 'estimated_cost', label: 'Costo Estimado' },
                                { id: 'selling_price', label: 'Precio de Venta ' },
                            ]}
                            items={opportunityDetail.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)}
                            count={opportunityDetail.length}
                            page={page - 1}
                            rowsPerPage={rowsPerPage}
                        />
                    </CardContent>
                </Card>
            </Stack>
        </>
    );
};

export default OpportunityDetailPage;
